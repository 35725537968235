.HomePage {
    .NewsOverview {
        padding-top: u($spacing-unit * 4) !important;
        padding-bottom: u($spacing-unit * 2) !important;
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 60%;
            background-color: $cc-grey;
        }

        &::before {
            background-image: url('../img/icons/background-min.jpg');
            // background-image: url('../img/icons/background.jpg');
            background-blend-mode: multiply;
            opacity: .12;

            @include mq ($from: $viewport--sm) {
                background-size: cover;
            }

            background-size: 250%;
            background-repeat: no-repeat;
        }

        &::after {
            z-index: -1;
        }

        &-title {
            margin-top: u($spacing-unit);
            @include font-size(25, 10);
            color: $cc-red;
            font-weight: normal;
        }

        &-items {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;
        }

        &-item {
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
            width: 100%;
            margin-bottom: u($spacing-unit * 2);
            background-color: $white;
            padding: u($spacing-unit);

            @include mq ($from: $viewport--md) {
                margin-bottom: u($spacing-unit * 2);
            }

            @include mq ($from: $viewport--sm, $until: $viewport--md - 1) {
                max-width: calc((100% / 2) - 15px);
            }

            @include mq ($from: $viewport--md) {
                max-width: calc((100% / 3) - 15px);
            }
        }
    }
}

.OverviewPagePage {
    .NewsOverview {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        max-width: 1075px;
        margin-left: auto;
        margin-right: auto;

        &-item {
            background-color: $white;
            text-decoration: none;
            color: $cc-black;
            h2{
                font-weight: normal !important;
            }

            @include mq ($from: $viewport--sm, $until: $viewport--md - 1) {
                max-width: calc((100% / 2) - 8px);
            }

            @include mq ($from: $viewport--md) {
                max-width: calc((100% / 2) - 20px);
            }

            border: 8px solid $white;
            display: flex;
            flex-direction: column;
        }

        &-media {
            position: relative;
        }

        &-date {
            position: absolute;
            right: 0;
            bottom: -1px;
            background-color: $white;
            color: $cc-green;
            font-size: 15px;
            padding: 8px;
        }

        &-content {
            padding: u($spacing-unit, $spacing-unit / 2);

            @include mq ($from: $viewport--md) {}

            margin: u(auto, 0);
        }
    }
}

.BlogContent {
    max-width: 1070px;
    margin: 0 auto;
}

.MoreNews {

    padding-top: u($spacing-unit * 4) !important;
    padding-bottom: u($spacing-unit * 2) !important;
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $cc-grey;
    }

    &::before {
        background-image: url('../img/icons/background-min.jpg');
        // background-image: url('../img/icons/background.jpg');
        background-blend-mode: multiply;
        opacity: .12;

        @include mq ($from: $viewport--sm) {
            background-size: cover;
        }

        background-size: 250%;
        background-repeat: no-repeat;
    }

    &::after {
        background-color: #f8f7f6;
        z-index: -1;
        opacity: .8;
    }

    .Title-line {
        font-family: $font-robotoSlab !important;
        text-transform: inherit;
        text-align: center;
    }

    &-items {
        width: 100%;
        margin: 0 auto;

        @include mq ($from: $viewport--sm) {
            max-width: 555px;
            display: flex;
            justify-content: space-between;
        }
    }

    &-item {
        text-decoration: none;
        width: 100%;

        @include mq ($from: $viewport--sm) {
            margin: 0 5px;
            max-width: 250px;
            display: flex;
            flex-direction: column;
        }

        &--prev {
            @include mq ($from: $viewport--sm) {
                text-align: right;
            }
        }

        &--next {
            @include mq ($from: $viewport--sm) {
                margin-left: auto;
            }
        }



    }

    &-title {
        margin-top: 10px;
        @include font-size(18, 10);
        color: $cc-black;
        font-weight: normal;
    }

    &-text {
        margin-top: auto;
        color: $cc-green;
        font-size: 12px;
    }
}
