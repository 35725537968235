/* Mixin: Image rendering */
@mixin image-rendering($boolean) {
    @if not is-null($boolean) {
        @if is-true($boolean) {
            image-rendering: -moz-crisp-edges;
            image-rendering: -o-crisp-edges;
            image-rendering: -webkit-optimize-contrast;
            -ms-interpolation-mode: nearest-neighbor;
        }
    }
}
