.Tab-content,
.Tab-media {
    visibility: hidden;

    &, *{
        height: 0;
    }

    &.is-active {
        &, *{
            height: auto;
        }
        visibility: visible;
    }
}

.Tabs {
    @include mq($from: $viewport--md) {
        display: flex;
    }
    &-wrapper{
        .Title-line{
            font-family: $font-robotoSlab !important;
            font-size: 25px;
            text-transform: initial;
        }
    }

    &-titles {
        margin: 1px 0 0 1px;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: u($spacing-unit);
        // border-top: 1px solid $cc-green;

        .Tab-title {
            @include mq ($from: 400px, $until: $viewport--sm - 1) {
                max-width: calc(100% / 2);
            }

            @include mq ($from: $viewport--sm, $until: $viewport--md - 1) {
                max-width: calc(100% / 3);
            }

            @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {
                max-width: calc(100% / 2);
            }

            @include mq ($from: $viewport--lg) {
                max-width: calc(100% / 3);
            }

            margin: -1px 0 0 -1px;
            border: 1px solid $cc-green;
            width: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include font-size(15, 0);
            color: $cc-green;
            text-align: center;
            padding: 15px 5px;
            font-weight: normal;
            position: relative;
            transition: color .4s;

            &::before {
                content: '';
                position: absolute;
                top: -6px;
                left: -4px;
                width: calc(100% + 8px);
                height: calc(100% + 12px);
                background-color: $cc-green;
                z-index: -1;
                opacity: 0;
                transition: opacity .4s;
            }

            &.is-active,
            &:hover {
                cursor: pointer;
                color: $white;

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    &-content--wrapper {
        width: 100%;

        @include mq($from: $viewport--md) {
            max-width: 575px;
            margin-right: u($spacing-unit * 3.5);
        }

    }
    .Tab-alart{
        font-size: 20px;
        color: $cc-red;
        font-weight: normal;
    }

    .Tabs-media--wrapper {
        border: 8px solid $cc-grey;
        height: 100%;
        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit * -1.5);
            @include fluid-type(max-width, $viewport--md, $viewport--xl, 300, 488);
            // max-width: 488px;\
        }
    }

}
