/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: Palette */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
@font-face {
  font-family: "adorn-condensed-sans";
  src: url("https://use.typekit.net/af/1ec651/00000000000000003b9b0983/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/1ec651/00000000000000003b9b0983/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/1ec651/00000000000000003b9b0983/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'robotoSlab';
  src: url("../fonts/robotoSlab/robotoslab-regular-webfont.woff2") format("woff2"), url("../fonts/robotoSlab/robotoslab-regular-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'robotoSlab';
  src: url("../fonts/robotoSlab/robotoslab-bold-webfont.woff2") format("woff2"), url("../fonts/robotoSlab/robotoslab-bold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "fieldwork";
  src: url("https://use.typekit.net/af/67fa9b/00000000000000003b9b3432/27/l?subset_id=2&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/67fa9b/00000000000000003b9b3432/27/d?subset_id=2&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/67fa9b/00000000000000003b9b3432/27/a?subset_id=2&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: "fieldwork";
  src: url("https://use.typekit.net/af/868bcb/00000000000000003b9b3436/27/l?subset_id=2&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/868bcb/00000000000000003b9b3436/27/d?subset_id=2&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/868bcb/00000000000000003b9b3436/27/a?subset_id=2&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: "fieldwork";
  src: url("https://use.typekit.net/af/0f0120/00000000000000003b9b3437/27/l?subset_id=2&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/0f0120/00000000000000003b9b3437/27/d?subset_id=2&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/0f0120/00000000000000003b9b3437/27/a?subset_id=2&fvd=i6&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600; }

/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Cookie */
/* Components: Modal */
/* Components: DigitalPulse */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: Units */
/* Functions: String */
/* Functions: SVG */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.375;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

/* HR */
hr {
  margin-top: 20px;
  margin-bottom: 19px;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img {
  display: block; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #3f51b5;
  text-decoration: underline; }
  a:hover, a:focus, a:active {
    color: #3949ab;
    text-decoration: none; }

/* List */
ol,
ul {
  margin-bottom: 20px;
  padding-left: 20px; }
  ol li,
  ul li {
    margin-bottom: 2px; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 4px;
    margin-bottom: 4px; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: #000000;
  background-color: #f1f075; }

/* Heading */
h1, .Alfa,
h2, .Bravo,
h3, .Charlie,
h4, .Delta,
h5, .Echo,
h6, .Foxtrot {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .fonts-loaded h1, .fonts-loaded .Alfa, .fonts-loaded
  h2, .fonts-loaded .Bravo, .fonts-loaded
  h3, .fonts-loaded .Charlie, .fonts-loaded
  h4, .fonts-loaded .Delta, .fonts-loaded
  h5, .fonts-loaded .Echo, .fonts-loaded
  h6, .fonts-loaded .Foxtrot {
    font-family: "robotoSlab", sans-serif; }
  h1 small, .Alfa small,
  h2 small, .Bravo small,
  h3 small, .Charlie small,
  h4 small, .Delta small,
  h5 small, .Echo small,
  h6 small, .Foxtrot small {
    font-weight: normal; }

h1, .Alfa {
  margin-bottom: 22px;
  font-size: 36px;
  line-height: 1.2; }

h2, .Bravo {
  margin-bottom: 22px;
  font-size: 30px;
  line-height: 1.2; }

h3, .Charlie {
  margin-bottom: 22px;
  font-size: 25px;
  line-height: 1.2; }

h4, .Delta {
  margin-bottom: 22px;
  font-size: 20px; }

h5, .Echo {
  margin-bottom: 22px;
  font-size: 18px; }

h6, .Foxtrot {
  margin-bottom: 22px;
  font-size: 16px; }

.Giga {
  margin-bottom: 22px;
  font-size: 96px;
  line-height: 1.1; }

.Mega {
  margin-bottom: 22px;
  font-size: 72px;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 22px;
  font-size: 48px;
  line-height: 1.1; }

.Milli {
  margin-bottom: 22px;
  font-size: 12px; }

.Micro {
  margin-bottom: 22px;
  font-size: 10px; }

/* Paragraph */
p {
  margin-bottom: 20px; }

.Lead {
  font-size: 18px; }

/* Main */
html {
  background-color: #ffffff;
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%; }
  html.fonts-loaded {
    font-family: "fieldwork", sans-serif; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%; }
  @media screen and (max-width: 47.9375em) {
    .show-nav body {
      padding-top: 100px; } }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 20px;
  padding: 0.625em 0.83333em 0.625em 0.83333em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 26.66667px; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 20px; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0.625em 1.25em 0.625em 1.25em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button:focus, .Button:hover, .Button.is-active {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 20px;
  position: relative; }

.Form-label {
  display: inline-block;
  line-height: 1; }
  .Form-item--input .Form-label {
    font-family: "robotoSlab", sans-serif;
    font-size: 12px;
    position: absolute;
    right: 6.66667px;
    left: 6.66667px;
    z-index: 2;
    transform-origin: top left;
    transition: transform .2s; }
  .Form-item:not(.is-required) .Form-label::after {
    font-size: 65%;
    content: attr(data-optional);
    margin-left: 2.5px;
    text-transform: lowercase; }
  .Form-item--input.is-active .Form-label {
    transform: translateY(-8px) scale(0.75); }
  .Form-item.is-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 5px;
  color: #bdbdbd;
  font-size: 12px; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 10px; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e0e0e0; }

.Form-item.is-error .Form-help--validation {
  color: #f44336; }

.Form-item.is-success .Form-help--validation {
  color: #4caf50; }

.Form-controls {
  position: relative; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 5px;
    margin-bottom: 0;
    padding-right: 20px;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

.Media-offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.Media-container {
  background: #000;
  box-sizing: border-box;
  font-family: 'Helvetica', Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top; }

.Media-container * {
  box-sizing: border-box; }

/* Hide native play button and control bar from iOS to favor plugin button */
.Media-container video::-webkit-media-controls,
.Media-container video::-webkit-media-controls-panel,
.Media-container video::-webkit-media-controls-panel-container,
.Media-container video::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important; }

.Media-fill-container,
.Media-fill-container .Media-container {
  height: 100%;
  width: 100%; }

.Media-fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }

.Media-container:focus {
  outline: none; }

.Media-iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%; }

.Media-embed,
.Media-embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.Media-fullscreen {
  overflow: hidden !important; }

.Media-container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }

.Media-container-fullscreen .Media-mediaelement,
.Media-container-fullscreen video {
  height: 100% !important;
  width: 100% !important; }

/* Start: LAYERS */
.Media-background {
  left: 0;
  position: absolute;
  top: 0; }

.Media-mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.Media-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

:root .Media-poster-img {
  display: none; }

.Media-poster-img {
  border: 0;
  padding: 0; }

.Media-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0; }

.Media-layer {
  z-index: 1; }

.Media-overlay-play {
  cursor: pointer; }

.Media-overlay-button {
  background: url("../img/media/controls.svg") no-repeat;
  background-position: 0 -39px;
  height: 80px;
  width: 80px; }

.Media-overlay:hover > .Media-overlay-button {
  background-position: -80px -39px; }

.Media-overlay-loading {
  height: 80px;
  width: 80px; }

.Media-overlay-loading-bg-img {
  animation: Media-loading-spinner 1s linear infinite;
  background: transparent url("../img/media/controls.svg") -160px -40px no-repeat;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1; }

@keyframes Media-loading-spinner {
  100% {
    transform: rotate(360deg); } }

/* End: LAYERS */
/* Start: CONTROL BAR */
.Media-controls {
  bottom: 0;
  display: flex;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 3; }

.Media-controls:not([style*='display: none']) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35)); }

.Media-button,
.Media-time,
.Media-time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px; }

.Media-button > button {
  background: transparent url("../img/media/controls.svg");
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px; }

/* :focus for accessibility */
.Media-button > button:focus {
  outline: dotted 1px #999; }

.Media-container-keyboard-inactive a,
.Media-container-keyboard-inactive a:focus,
.Media-container-keyboard-inactive button,
.Media-container-keyboard-inactive button:focus,
.Media-container-keyboard-inactive [role=slider],
.Media-container-keyboard-inactive [role=slider]:focus {
  outline: 0; }

/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
.Media-time {
  box-sizing: content-box;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto; }

/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
.Media-play > button {
  background-position: 0 0; }

.Media-pause > button {
  background-position: -20px 0; }

.Media-replay > button {
  background-position: -160px 0; }

/* End: Play/Pause/Stop */
/* Start: Progress Bar */
.Media-time-rail {
  direction: ltr;
  flex-grow: 1;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative; }

.Media-time-total,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-current,
.Media-time-float,
.Media-time-hovered,
.Media-time-float-current,
.Media-time-float-corner,
.Media-time-marker {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute; }

.Media-time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%; }

.Media-time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%; }

@keyframes buffering-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.Media-time-loaded {
  background: rgba(255, 255, 255, 0.3); }

.Media-time-current,
.Media-time-handle-content {
  background: rgba(255, 255, 255, 0.9); }

.Media-time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10; }

.Media-time-hovered.negative {
  background: rgba(0, 0, 0, 0.2); }

.Media-time-current,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%; }

.Media-time-buffering {
  transform: scaleX(1); }

.Media-time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1); }

.Media-time-hovered.no-hover {
  transform: scaleX(0) !important; }

.Media-time-handle,
.Media-time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  transform: translateX(0);
  z-index: 11; }

.Media-time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  transform: scale(0);
  width: 10px; }

.Media-time-rail:hover .Media-time-handle-content,
.Media-time-rail .Media-time-handle-content:focus,
.Media-time-rail .Media-time-handle-content:active {
  transform: scale(1); }

.Media-time-float {
  background: #eee;
  border: solid 1px #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 36px; }

.Media-time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px; }

.Media-time-float-corner {
  border: solid 5px #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.Media-long-video .Media-time-float {
  margin-left: -23px;
  width: 64px; }

.Media-long-video .Media-time-float-current {
  width: 60px; }

.Media-broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%; }

/* End: Progress Bar */
/* Start: Fullscreen */
.Media-fullscreen-button > button {
  background-position: -80px 0; }

.Media-unfullscreen > button {
  background-position: -100px 0; }

/* End: Fullscreen */
/* Start: Mute/Volume */
.Media-mute > button {
  background-position: -60px 0; }

.Media-unmute > button {
  background-position: -40px 0; }

.Media-volume-button {
  position: relative; }

.Media-volume-button > .Media-volume-slider {
  -webkit-backface-visibility: hidden;
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1; }

.Media-volume-button:hover {
  border-radius: 0 0 4px 4px; }

.Media-volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  width: 2px; }

.Media-volume-current {
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%; }

.Media-volume-handle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 16px; }

.Media-horizontal-volume-slider {
  display: block;
  height: 36px;
  position: relative;
  vertical-align: middle;
  width: 56px; }

.Media-horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 8px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 50px; }

.Media-horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.Media-horizontal-volume-handle {
  display: none; }

/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
.Media-captions-button,
.Media-chapters-button {
  position: relative; }

.Media-captions-button > button {
  background-position: -140px 0; }

.Media-chapters-button > button {
  background-position: -180px 0; }

.Media-captions-button > .Media-captions-selector,
.Media-chapters-button > .Media-chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px; }

.Media-chapters-button > .Media-chapters-selector {
  margin-right: -55px;
  width: 110px; }

.Media-captions-selector-list,
.Media-chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item,
.Media-chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item:hover,
.Media-chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important; }

.Media-captions-selector-input,
.Media-chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute; }

.Media-captions-selector-label,
.Media-chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 10px 0;
  width: 100%; }

.Media-captions-selected,
.Media-chapters-selected {
  color: #21f8f8; }

.Media-captions-translations {
  font-size: 10px;
  margin: 0 0 5px; }

.Media-captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center; }

.Media-captions-layer a {
  color: #fff;
  text-decoration: underline; }

.Media-captions-layer[lang=ar] {
  font-size: 20px;
  font-weight: normal; }

.Media-captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%; }

.Media-captions-position-hover {
  bottom: 35px; }

.Media-captions-text,
.Media-captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap; }

.Media-container.Media-hide-cues video::-webkit-media-text-track-container {
  display: none; }

/* End: Track (Captions and Chapters) */
/* Start: Error */
.Media-overlay-error {
  position: relative; }

.Media-overlay-error > img {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: -1; }

.Media-cannotplay,
.Media-cannotplay a {
  color: #fff;
  font-size: 0.8em; }

.Media-cannotplay {
  position: relative; }

.Media-cannotplay p,
.Media-cannotplay a {
  display: inline-block;
  padding: 0 15px;
  width: 100%; }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-left: -5px; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-left: 5px; }

.Grid--withSmallGutter {
  margin-left: -10px; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-left: 10px; }

.Grid--withGutter {
  margin-left: -20px; }
  .Grid--withGutter > .Grid-cell {
    padding-left: 20px; }

.Grid--withLargeGutter {
  margin-left: -40px; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-left: 40px; }

.Grid--withExtraLargeGutter {
  margin-left: -80px; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-left: 80px; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto; }

.Nav-item {
  margin: 0; }

.Nav-link {
  user-select: none;
  display: block; }
  .Nav-link, .Nav-link:active, .Nav-link:focus, .Nav-link:hover {
    text-decoration: none; }

.Nav--stacked .Nav-list {
  flex-direction: column;
  flex-wrap: nowrap; }

.Nav--fit:not(.Nav--stacked) .Nav-item {
  flex: 1 1 auto; }

.Nav--alignCenter {
  text-align: center; }
  .Nav--alignCenter:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: center; }

.Nav--alignRight {
  text-align: right; }
  .Nav--alignRight:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: flex-end; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1240px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%; }
  .Container--xl {
    max-width: 1480px; }
  .Container--lg {
    max-width: 1320px; }
  .Container--md {
    max-width: 1240px; }
  .Container--sm {
    max-width: 900px; }
  .Container--xs {
    max-width: 540px; }
  @media screen and (min-width: 48em) {
    .Container {
      max-width: 1280px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1280px; }
      .Container--sm {
        max-width: 940px; }
      .Container--xs {
        max-width: 580px; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer"; }

.Header {
  position: relative;
  z-index: 3;
  grid-area: header; }

.Footer {
  position: relative;
  z-index: 1;
  grid-area: footer; }
  .Footer > .Container {
    height: 100%; }

.Main {
  position: relative;
  z-index: 2;
  grid-area: main; }
  .Main > .Container {
    padding-top: 20px;
    padding-bottom: 20px; }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        grid-gap: 40px; }
        .Main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .Main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .Main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 20px; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 3.33333px 6.66667px 3.33333px 6.66667px; }
  .Table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0; }
  .Table tbody tr + tr {
    border-top: 1px solid #e0e0e0; }
  .Table tfoot {
    background-color: #f5f5f5;
    border-top: 2px solid #e0e0e0; }
  @media screen and (max-width: 63.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 1px solid #e0e0e0;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 1px solid #e0e0e0;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -10px -10px 10px;
          padding: 10px;
          background-color: #fafafa;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 63.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.Toggle {
  height: 24px;
  width: 30px;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg); }
  .Toggle-item {
    height: 2px;
    width: 100%;
    position: absolute;
    transition-property: top, transform, opacity;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    background-color: currentColor;
    border-radius: 2px;
    display: block;
    transform: rotate(0deg); }
    @media screen and (max-width: 47.9375em) {
      .show-nav .Toggle-item {
        background-color: #76b729; } }
    .Toggle-item:nth-child(1) {
      top: 0; }
      .show-nav .Toggle-item:nth-child(1) {
        top: 11px;
        transform: rotate(135deg); }
    .Toggle-item:nth-child(2) {
      top: 11px; }
      .show-nav .Toggle-item:nth-child(2) {
        opacity: 0; }
    .Toggle-item:nth-child(3) {
      top: 22px; }
      .show-nav .Toggle-item:nth-child(3) {
        top: 11px;
        transform: rotate(-135deg); }

/* Alert */
.Alert--success {
  color: #659667;
  background-color: #eef6ef;
  border-color: #dfeddf; }
  .Alert--success a {
    color: #4c714d; }

.Alert--info {
  color: #5690bf;
  background-color: #ebf4fb;
  border-color: #d7e9f7; }
  .Alert--info a {
    color: #396d96; }

.Alert--warning {
  color: #bf8c40;
  background-color: #fcf4e9;
  border-color: #f9ead3; }
  .Alert--warning a {
    color: #8f6930; }

.Alert--danger {
  color: #c56c66;
  background-color: #fceeed;
  border-color: #f8dbd9; }
  .Alert--danger a {
    color: #a2453e; }

/* Button */
.Button .Button-arrow {
  margin-left: 35px; }
  .Button .Button-arrow * {
    transition: fill .3s; }

.Button--primary {
  border: none;
  color: #a83345;
  font-family: "robotoSlab", sans-serif;
  padding: 10px 15px; }
  .Button--primary .Button-arrow * {
    fill: #a83345; }
  .Button--primary::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background-image: url("../img/icons/line.svg");
    background-repeat: no-repeat;
    background-size: 100% 5px; }
  .Button--primary:hover {
    color: #d06373; }
    .Button--primary:hover .Button-arrow * {
      fill: #d06373; }

.Button--secondary {
  color: #76b729;
  border-color: #76b729;
  background-color: transparent;
  transition: background-color .3s, color .3s; }
  .Button--secondary .Button-arrow {
    margin-left: 10px; }
  .Button--secondary--full {
    background-color: #76b729;
    color: #ffffff; }
    .Button--secondary--full:hover {
      background-color: #85ce2e !important; }
  .Button--secondary:hover {
    color: #ffffff;
    background-color: #76b729; }
    .Button--secondary:hover .Button-arrow * {
      fill: #ffffff; }

.Button--link {
  color: #76b729;
  margin-bottom: 0px;
  font-size: 13px;
  text-decoration: underline; }
  .Button--link:hover {
    color: #76b729;
    text-decoration: none; }

.Button--info {
  background-color: #2196f3;
  color: #ffffff; }
  .Button--info:not(.Button--outline):active, .Button--info:not(.Button--outline):focus, .Button--info:not(.Button--outline):hover, .Button--info:not(.Button--outline).is-active {
    background-color: #1976d2;
    color: #ffffff; }
  .Button--info.Button--outline {
    color: #2196f3; }
    .Button--info.Button--outline:active, .Button--info.Button--outline:focus, .Button--info.Button--outline:hover, .Button--info.Button--outline.is-active {
      color: #1976d2; }

.Button--success {
  background-color: #4caf50;
  color: #ffffff; }
  .Button--success:not(.Button--outline):active, .Button--success:not(.Button--outline):focus, .Button--success:not(.Button--outline):hover, .Button--success:not(.Button--outline).is-active {
    background-color: #388e3c;
    color: #ffffff; }
  .Button--success.Button--outline {
    color: #4caf50; }
    .Button--success.Button--outline:active, .Button--success.Button--outline:focus, .Button--success.Button--outline:hover, .Button--success.Button--outline.is-active {
      color: #388e3c; }

.Button--warning {
  background-color: #ff9800;
  color: #ffffff; }
  .Button--warning:not(.Button--outline):active, .Button--warning:not(.Button--outline):focus, .Button--warning:not(.Button--outline):hover, .Button--warning:not(.Button--outline).is-active {
    background-color: #f57c00;
    color: #ffffff; }
  .Button--warning.Button--outline {
    color: #ff9800; }
    .Button--warning.Button--outline:active, .Button--warning.Button--outline:focus, .Button--warning.Button--outline:hover, .Button--warning.Button--outline.is-active {
      color: #f57c00; }

.Button--danger {
  background-color: #f44336;
  color: #ffffff; }
  .Button--danger:not(.Button--outline):active, .Button--danger:not(.Button--outline):focus, .Button--danger:not(.Button--outline):hover, .Button--danger:not(.Button--outline).is-active {
    background-color: #d32f2f;
    color: #ffffff; }
  .Button--danger.Button--outline {
    color: #f44336; }
    .Button--danger.Button--outline:active, .Button--danger.Button--outline:focus, .Button--danger.Button--outline:hover, .Button--danger.Button--outline.is-active {
      color: #d32f2f; }

.Button--outline {
  background-color: transparent;
  border-color: currentColor; }

.Button--large {
  font-size: 20px;
  padding-right: 40px;
  padding-left: 40px; }

.Button--small {
  font-size: 12px;
  padding-right: 13.33333px;
  padding-left: 13.33333px; }

.Button--full {
  display: block;
  width: 100%; }

.Button--bare {
  border: 0;
  border-radius: 0;
  padding: 0; }

.Modal {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
  padding: 0 !important; }
  .Modal, .Modal:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .Modal::before {
    background-color: rgba(0, 0, 0, 0.7);
    content: '';
    z-index: -1; }
  .Modal:not(.is-active) {
    opacity: 0;
    visibility: hidden; }
  .Modal-inner {
    display: flex;
    align-items: center;
    min-height: 100%; }
  .Modal-innerBody {
    border-radius: 0;
    border-color: #f2eeed;
    margin: 20px 20px 20px 20px;
    width: auto;
    margin: 40px auto;
    max-width: 90vw;
    width: 100%; }
  .Modal-header {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 10px 10px 10px; }
  .Modal-title {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 0; }
  .Modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 32px;
    line-height: 1.2;
    transition-property: opacity;
    transition-duration: visibility;
    transition-timing-function: linear;
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    color: #ffffff;
    padding: 10px 10px 10px 10px;
    margin: -10px -10px -10px auto; }
    html:not(.is-tabbing) .Modal-close:focus {
      outline: 0; }
    .Modal-close:not(:hover) {
      opacity: .5; }
  .Modal-body {
    padding: 0.625em 0.625em 0.625em 0.625em; }
  .Modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0.625em 0.625em 0.625em 0.625em;
    border-top: 1px solid #e0e0e0; }

/* Outdated Browser */
.Outdated {
  background-color: #f44336;
  color: #ffffff;
  font-size: 12px;
  padding: 12px 24px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500; }
  .Outdated-title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px; }
  .Outdated-text {
    margin-bottom: 0;
    line-height: 1; }
  .Outdated-button {
    border: 2px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-weight: 700;
    line-height: 1;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    width: 230px;
    /*need for IE*/
    position: absolute;
    top: 12px;
    right: 24px; }
    .Outdated-button:hover {
      background-color: #ffffff;
      color: #f44336; }
  * html .Outdated {
    position: absolute; }

/* Cookie */
.CookieConsent {
  transition-property: transform, opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: 0s, 200ms, 200ms;
  background-color: #76b729;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4; }
  .CookieConsent:not(.is-active) {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden; }
  .CookieConsent > .Container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .CookieConsent a {
    font-weight: 700; }
    .CookieConsent a, .CookieConsent a:active, .CookieConsent a:focus, .CookieConsent a:hover {
      color: inherit; }
  .CookieConsent-close {
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    font-size: 150%;
    margin-left: 20px;
    line-height: 1;
    transition-property: opacity;
    transition-duration: 200ms; }
    .CookieConsent-close:not(:hover) {
      opacity: .75; }

/* Header */
.show-dropdown .Header {
  opacity: 1 !important; }

.Header {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  opacity: 1; }
  @media screen and (max-width: 47.9375em) {
    .show-nav .Header {
      height: 75px; } }
  .Header--sticky {
    transition-property: background-color, color, height, transform, opacity;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Header.is-unpinned {
    transform: translateY(-100%);
    opacity: 0; }
  .Header.is-notTop {
    background-color: #ffffff; }
  .Header-top {
    background-color: #76b729;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: height .3s, padding .3s;
    z-index: 3;
    position: relative; }
    .Header-top .Nav--socialMedia .Nav-link:hover .Nav-icon * {
      fill: #f2eeed; }
    @media screen and (max-width: 47.9375em) {
      .show-nav .Header-top {
        height: 0;
        padding: 0;
        z-index: -1; } }
  .Header-main {
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: background-color .3s; }
    @media screen and (max-width: 47.9375em) {
      .show-nav .Header-main {
        background-color: #f2eeed; } }
  .Header > .Container {
    display: flex;
    align-items: center; }

/* Navigation */
.Navigation .Logo {
  margin-right: auto; }

.Navigation-toggle {
  display: block; }

.Navigation-body {
  display: flex; }
  @media screen and (max-width: 47.9375em) {
    .Navigation-body {
      position: fixed;
      top: 75px;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      transition-property: opacity, visibility;
      transition-duration: 200ms;
      transition-timing-function: linear;
      background-color: #f2eeed;
      flex-direction: column;
      padding: 20px;
      height: calc(100% - 75px); }
      html:not(.show-nav) .Navigation-body {
        opacity: 0;
        visibility: hidden; } }

/* Types */
/* Main */
.Nav--main {
  width: 100%; }
  @media screen and (max-width: 47.9375em) {
    .Nav--main {
      display: none; } }
  .Nav--main--mobile {
    display: none; }
    @media screen and (max-width: 47.9375em) {
      .Nav--main--mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1; } }
  .Nav--main .Nav-list {
    align-items: center; }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Nav--main .Nav-list {
        margin-left: -13.33333px; } }
    @media screen and (min-width: 64em) {
      .Nav--main .Nav-list {
        margin-left: -40px; } }
    @media screen and (max-width: 47.9375em) {
      .Nav--main .Nav-list {
        flex-direction: column;
        align-items: center;
        justify-content: center; } }
  .Nav--main .Nav-item {
    font-family: "robotoSlab", sans-serif;
    text-transform: lowercase;
    margin-bottom: 0px;
    font-size: 17px;
    letter-spacing: .94px;
    z-index: 3; }
    .Nav--main .Nav-item.Logo {
      position: relative;
      z-index: 3;
      height: 81px; }
      .Nav--main .Nav-item.Logo::before, .Nav--main .Nav-item.Logo::after {
        content: '';
        position: absolute;
        border-radius: 50%; }
        @media screen and (max-width: 47.9375em) {
          .Nav--main .Nav-item.Logo::before, .Nav--main .Nav-item.Logo::after {
            display: none; } }
      .Nav--main .Nav-item.Logo::before {
        width: 255px;
        height: 255px;
        bottom: -50px;
        background-color: transparent;
        border: 4px solid #ffffff; }
        @media screen and (min-width: 48em) and (max-width: 63.9375em) {
          .Nav--main .Nav-item.Logo::before {
            left: -55px; } }
        @media screen and (min-width: 64em) {
          .Nav--main .Nav-item.Logo::before {
            left: -28px; } }
      .Nav--main .Nav-item.Logo::after {
        width: 235px;
        height: 235px;
        bottom: -40px;
        background-color: #ffffff;
        z-index: -1; }
        @media screen and (min-width: 48em) and (max-width: 63.9375em) {
          .Nav--main .Nav-item.Logo::after {
            left: -45px; } }
        @media screen and (min-width: 64em) {
          .Nav--main .Nav-item.Logo::after {
            left: -18px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Nav--main .Nav-item {
        padding-left: 13.33333px; } }
    @media screen and (min-width: 64em) {
      .Nav--main .Nav-item {
        padding-left: 40px; } }
    @media screen and (max-width: 47.9375em) {
      .Nav--main .Nav-item {
        color: #76b729; }
        .Nav--main .Nav-item + .Nav-item {
          margin-top: 10px; }
        .show-nav .Nav--main .Nav-item {
          transition-property: opacity, transform;
          transition-duration: 300ms, 1s;
          transition-timing-function: linear, cubic-bezier(0.19, 1, 0.22, 1); }
          .show-nav .Nav--main .Nav-item:nth-child(1) {
            transition-delay: 0.02s; }
          .show-nav .Nav--main .Nav-item:nth-child(2) {
            transition-delay: 0.04s; }
          .show-nav .Nav--main .Nav-item:nth-child(3) {
            transition-delay: 0.06s; }
          .show-nav .Nav--main .Nav-item:nth-child(4) {
            transition-delay: 0.08s; }
          .show-nav .Nav--main .Nav-item:nth-child(5) {
            transition-delay: 0.1s; }
          .show-nav .Nav--main .Nav-item:nth-child(6) {
            transition-delay: 0.12s; }
          .show-nav .Nav--main .Nav-item:nth-child(7) {
            transition-delay: 0.14s; }
          .show-nav .Nav--main .Nav-item:nth-child(8) {
            transition-delay: 0.16s; }
          .show-nav .Nav--main .Nav-item:nth-child(9) {
            transition-delay: 0.18s; }
          .show-nav .Nav--main .Nav-item:nth-child(10) {
            transition-delay: 0.2s; }
        html:not(.show-nav) .Nav--main .Nav-item {
          opacity: 0;
          transform: translateY(20px); } }
  .Nav--main .Nav-link .Icon-down {
    height: 10px;
    width: 10px; }
    @media screen and (max-width: 47.9375em) {
      .Nav--main .Nav-link .Icon-down {
        display: none; } }
    .Nav--main .Nav-link .Icon-down * {
      transition: fill .4s;
      fill: #000000; }
  .Nav--main .Nav-link.is-active, .Nav--main .Nav-link:hover {
    color: #76b729 !important; }
    .Nav--main .Nav-link.is-active .Icon-down *, .Nav--main .Nav-link:hover .Icon-down * {
      fill: #76b729; }
  @media screen and (max-width: 47.9375em) {
    .Nav--main .Nav-link.is-active, .Nav--main .Nav-link:hover {
      font-weight: bold; } }
  .Nav--main .Nav-link, .Nav--main .Nav-link:active, .Nav--main .Nav-link:focus, .Nav--main .Nav-link:hover {
    color: currentColor; }

/* Lang */
.Nav--lang {
  position: relative;
  z-index: 1; }
  @media screen and (max-width: 47.9375em) {
    .show-nav .Nav--lang {
      position: relative;
      z-index: -1; } }
  .Nav--lang:not(:hover) .Nav-list {
    opacity: 0;
    visibility: hidden; }
  .Nav--lang .Nav-toggle {
    cursor: default;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: .67px;
    color: #ffffff;
    font-family: "robotoSlab", sans-serif; }
    .Nav--lang .Nav-toggle svg {
      height: 7px;
      width: 7px; }
      .Nav--lang .Nav-toggle svg * {
        fill: #ffffff; }
  .Nav--lang .Nav-list {
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 100%;
    right: -2px;
    color: #ffffff;
    font-family: "robotoSlab", sans-serif;
    background-color: #76b729;
    flex-direction: column; }
  .Nav--lang .Nav-link {
    color: currentColor;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px; }
    .Nav--lang .Nav-link:not(.is-active):hover {
      background-color: #85ce2e; }

.Nav--footer {
  max-width: 600px;
  width: 100%;
  margin: 0 20px; }
  @media screen and (max-width: 28.6875em) {
    .Nav--footer {
      margin: 20px 0; } }
  .Nav--footer .Nav-list {
    margin-left: -10px;
    display: block;
    columns: 2; }
    @media screen and (min-width: 37.5em) {
      .Nav--footer .Nav-list {
        columns: 3; } }
  .Nav--footer .Nav-item {
    padding-left: 10px; }
    .Nav--footer .Nav-item:hover .Nav-link {
      color: #76b729; }
  .Nav--footer .Nav-link {
    color: #2c2c2c;
    margin-bottom: 0px;
    font-size: 13px;
    line-height: 30px;
    font-family: "robotoSlab", sans-serif;
    transition: color .3s; }

/* socialMedia */
.Nav--socialMedia .Nav-list {
  flex-wrap: nowrap; }

.Nav--socialMedia .Nav-icon, .Nav--socialMedia .Nav-link {
  max-height: 18px; }

.Nav--socialMedia .Nav-link {
  display: flex;
  justify-content: center;
  align-items: center; }

.Nav--socialMedia .Nav-item {
  margin-right: 10px; }

.Nav--socialMedia .Nav-icon * {
  transition: fill .3s; }

.Nav--privacy .Nav-list {
  margin-left: -10px; }

.Nav--privacy .Nav-item,
.Nav--privacy .Nav-link {
  font-family: "fieldwork", sans-serif;
  margin-bottom: 0px;
  font-size: 12px;
  color: #2c2c2c;
  opacity: .6; }

.Nav--privacy .Nav-item {
  padding-left: 20px; }
  .Nav--privacy .Nav-item:hover .Nav-link {
    text-decoration: underline; }

.Nav--privacy .Nav-link {
  opacity: 1 !important; }

.Nav-magicLine {
  border-top: 5px solid #76b729;
  display: block;
  transition: opacity .2s;
  z-index: 2;
  position: absolute;
  top: -10px;
  right: 0;
  left: 0; }
  .Nav-magicLine:not(.is-loaded) {
    opacity: 0; }
  @media screen and (max-width: 47.9375em) {
    .Nav-magicLine {
      display: none; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Nav-magicLine {
      margin-left: 13.33333px; } }
  @media screen and (min-width: 64em) {
    .Nav-magicLine {
      margin-left: 40px; } }

/* Logo */
.Logo {
  flex-shrink: 0; }
  .Logo svg {
    display: block;
    width: 115px; }
    @media screen and (max-width: 47.9375em) {
      .Header .Logo svg {
        width: 65px;
        height: 55px; } }
    @media screen and (min-width: 48em) {
      .Header .Logo svg {
        position: relative;
        left: 10px; } }

/* Fields */
/* Fields: field */
.Form-field--input,
.Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  background-color: #ffffff;
  border-radius: 0;
  border: 1px solid #bdbdbd;
  color: #424242;
  vertical-align: middle;
  width: 100%; }
  @media screen and (min-width: 37.5em) {
    .Form-field--input,
    .Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
      max-width: 300px; } }
  .Form-item.is-error .Form-field--input,
  .Form-item.is-error .Form-field--textarea, .Form-item.is-error .Form-field--select {
    border-color: #f44336; }
  .Form-item.is-success .Form-field--input,
  .Form-item.is-success .Form-field--textarea, .Form-item.is-success .Form-field--select {
    border-color: #4caf50; }

.Form-field--input,
.Form-field--textarea, .Form-field--select {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }
  .Form-field--input:focus,
  .Form-field--textarea:focus, .Form-field--select:focus {
    border-color: #757575;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px #64b5f6;
    outline: 0;
    z-index: 1; }
    .Form-item.is-error .Form-field--input:focus,
    .Form-item.is-error .Form-field--textarea:focus, .Form-item.is-error .Form-field--select:focus {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
    .Form-item.is-success .Form-field--input:focus,
    .Form-item.is-success .Form-field--textarea:focus, .Form-item.is-success .Form-field--select:focus {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(76, 175, 80, 0.6); }

.Form-field {
  font-family: inherit;
  position: relative; }

.Form-indicator {
  user-select: none; }

.Form-field--xs {
  max-width: 60px !important; }

.Form-field--sm {
  max-width: 100px !important; }

.Form-field--md {
  max-width: 200px !important; }

.Form-field--lg {
  max-width: 300px !important; }

.Form-field--xl {
  max-width: 400px !important; }

.Form-field--full {
  max-width: none !important; }

/* Fields: check */
.Form-field--radio,
.Form-field--checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 12px;
  font-family: "fieldwork", sans-serif !important; }
  .Form-field--radio a,
  .Form-field--checkbox a {
    color: #76b729; }
  .Form-field--radio p,
  .Form-field--checkbox p {
    margin: 0; }
  .Form-field--radio:not(:first-of-type),
  .Form-field--checkbox:not(:first-of-type) {
    margin-top: 2.5px; }
  .Form-field--radio input[type="radio"],
  .Form-field--radio input[type="checkbox"],
  .Form-field--checkbox input[type="radio"],
  .Form-field--checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--radio input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
      background-color: #76b729;
      border-color: #76b729; }
    .Form-field--radio input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:focus ~ .Form-indicator {
      border-color: #757575;
      box-shadow: 0 0 2px 1px #64b5f6; }
  .Form-field--radio .Form-indicator,
  .Form-field--checkbox .Form-indicator {
    background-color: transparent;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.5625em 0.5625em;
    border: 1px solid #76b729;
    flex-shrink: 0;
    height: 1.5625em;
    width: 1.5625em;
    margin-right: 10px;
    position: relative;
    top: 0.1875em; }

.Form-field--radio > .Form-indicator {
  border-radius: 100%; }

.Form-field--radio input[type="radio"]:checked ~ .Form-indicator::after {
  background-color: #ffffff;
  border-radius: 100%;
  content: '';
  height: 0.3em;
  width: 0.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Form-field--checkbox > .Form-indicator {
  border-radius: 0; }

.Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E"); }

/* Fields: file */
.Form-field--file {
  cursor: pointer;
  display: block; }
  .Form-field--file input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--file input[type='file']:focus ~ .Form-indicator {
      border-color: #757575;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px #64b5f6; }
      .Form-item.is-error .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
      .Form-item.is-success .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(76, 175, 80, 0.6); }
  .Form-field--file .Form-indicator {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
    border-radius: 0;
    border: 1px solid #bdbdbd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: #424242;
    display: block;
    line-height: normal;
    overflow: hidden;
    padding: 0.20833em 0.41667em 0.20833em 0.41667em;
    position: relative;
    white-space: nowrap;
    width: 100%; }
    .Form-field--file .Form-indicator::before {
      content: attr(data-file-text);
      display: block;
      padding-right: 100px; }
    .Form-field--file .Form-indicator::after {
      background-color: #eeeeee;
      border-left: 1px solid #bdbdbd;
      color: #757575;
      content: attr(data-file-button);
      padding: 0.20833em 0.41667em 0.20833em 0.41667em;
      text-align: center;
      width: 100px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0; }
  @media screen and (min-width: 37.5em) {
    .Form-field--file {
      max-width: 300px; } }

/* Fields: input */
.Form-field--input {
  padding: 0.20833em 0.41667em 0.20833em 0.41667em; }

.Form-field--textarea {
  padding: 0.08333em 0.41667em 0.08333em 0.41667em; }

/* Fields: select */
select {
  cursor: pointer;
  width: 100% !important; }
  @media screen and (min-width: 37.5em) {
    select {
      max-width: 300px; } }

.Form-field--select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20292.36%20402%22%3E%3Cpath%20d%3D%22M18.27%2C164.45H274.09a18.17%2C18.17%2C0%2C0%2C0%2C12.85-31.12L159%2C5.42a17.93%2C17.93%2C0%2C0%2C0-25.7%2C0L5.42%2C133.33a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7A17.57%2C17.57%2C0%2C0%2C0%2C18.27%2C164.45Z%22%2F%3E%3Cpath%20d%3D%22M274.09%2C237.55H18.27A17.57%2C17.57%2C0%2C0%2C0%2C5.42%2C243a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7l127.91%2C127.9a17.92%2C17.92%2C0%2C0%2C0%2C25.7%2C0L286.93%2C268.66a18.27%2C18.27%2C0%2C0%2C0-12.85-31.12Z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: normal;
  overflow: hidden;
  padding: 0.20833em 2.5em 0.20833em 0.41667em; }

.Form-field--select[multiple] {
  height: auto; }
  .Form-field--select[multiple] option {
    padding: 0.10417em 0.41667em 0.10417em 0.41667em; }
    .Form-field--select[multiple] option + option {
      border-top: 1px solid #bdbdbd; }

/* Slider */
/* Slider: core */
.Slider {
  position: relative;
  z-index: 1;
  /* Fix of Webkit flickering */
  overflow: hidden; }
  .Slider--no-flexbox .Slider-item {
    float: left; }
  .Slider--vertical > .Slider-items {
    flex-direction: column; }
  .Slider-items {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform; }
  .Slider--android .Slider-item, .Slider-items {
    transform: translate3d(0px, 0, 0); }
  .Slider--multirow > .Slider-items {
    flex-wrap: wrap; }
  .Slider--free-mode > .Slider-items {
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .Slider-item {
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    transition-property: transform; }
    .Slider-item.is-blank {
      visibility: hidden; }
  .Slider--autoheight,
  .Slider--autoheight .Slider-item {
    height: auto; }
  .Slider--autoheight .Slider-items {
    align-items: flex-start;
    transition-property: transform, height; }
  .Slider--3d {
    perspective: 1200px; }
    .Slider--3d .Slider-items,
    .Slider--3d .Slider-item,
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom,
    .Slider--3d .swiper-cube-shadow {
      transform-style: preserve-3d; }
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10; }
    .Slider--3d .Slider-item-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .Slider--wp8-horizontal,
  .Slider--wp8-horizontal > .Slider-items {
    touch-action: pan-y; }
  .Slider--wp8-vertical,
  .Slider--wp8-vertical > .Slider-items {
    touch-action: pan-x; }

/* Slider: a11y */
.Slider .Slider-notification {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  pointer-events: none;
  opacity: 0; }

/* Slider: fade */
.Slider--fade.Slider--free-mode .Slider-item {
  transition-timing-function: ease-out; }

.Slider--fade .Slider-item {
  pointer-events: none;
  transition-property: opacity; }
  .Slider--fade .Slider-item .Slider-item {
    pointer-events: none; }

.Slider--fade .is-active,
.Slider--fade .is-active .is-active {
  pointer-events: auto; }

/* Slider: coverflow */
.Slider--coverflow .Slider-items {
  -ms-perspective: 1200px;
  /* Windows 8 IE 10 fix */ }

/* Slider: cube */
.Slider--cube {
  overflow: visible; }
  .Slider--cube .Slider-item {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    pointer-events: none;
    transform-origin: 0 0;
    visibility: hidden;
    z-index: 1; }
    .Slider--cube .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--cube.Slider--rtl .Slider-item {
    transform-origin: 100% 0; }
  .Slider--cube .is-active,
  .Slider--cube .is-active .is-active {
    pointer-events: auto; }
  .Slider--cube .is-active,
  .Slider--cube .is-next,
  .Slider--cube .is-prev,
  .Slider--cube .is-next + .Slider-item {
    pointer-events: auto;
    visibility: visible; }
  .Slider--cube .swiper-slide-shadow-top,
  .Slider--cube .swiper-slide-shadow-bottom,
  .Slider--cube .swiper-slide-shadow-left,
  .Slider--cube .swiper-slide-shadow-right {
    backface-visibility: hidden;
    z-index: 0; }
  .Slider--cube .swiper-cube-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #000000;
    filter: blur(50px);
    opacity: 0.6; }

/* Slider: flip */
.Slider--flip {
  overflow: visible; }
  .Slider--flip .Slider-item {
    backface-visibility: hidden;
    pointer-events: none;
    z-index: 1; }
    .Slider--flip .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--flip .is-active,
  .Slider--flip .is-active .is-active {
    pointer-events: auto; }
  .Slider--flip .Slider-item--shadow-top,
  .Slider--flip .Slider-item--shadow-bottom,
  .Slider--flip .Slider-item--shadow-left,
  .Slider--flip .Slider-item--shadow-right {
    backface-visibility: hidden;
    z-index: 0; }

/* Slider: lazy */
.Slider-preloader {
  height: 42px;
  width: 42px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
  margin-top: -21px;
  margin-left: -21px; }
  .Slider-preloader:after {
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: block; }

.Slider-preloader--white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/* Slider: navigation */
.Slider-button {
  height: 44px;
  width: 27px;
  position: absolute;
  top: 50%;
  z-index: 10;
  margin-top: -22px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px 44px; }
  .Slider-button.is-disabled {
    cursor: auto;
    opacity: 0.35;
    pointer-events: none; }
  .Slider-button.is-locked {
    display: none; }

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAjCAMAAABB9MFTAAAAG1BMVEUAAAD////////////////////////////////rTT7CAAAACHRSTlMAQFBgkJ+gv9iGVP8AAAAxSURBVHgBY0AHzEwYQmwcHFiEmEeFQIAFU4iBg4MdM1w5OFiJEBwVZMEmyIABGBkZAF/NAyTxd+ksAAAAAElFTkSuQmCC);
  right: auto;
  left: 10px; }

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAjCAMAAABB9MFTAAAAJFBMVEUAAAD///////////////////////////////////////////+0CY3pAAAAC3RSTlMAEDBAcI+QoM/Q7wWHhpAAAAAxSURBVCjPY2BiZWRAB2zcHBiCjJyjgmQKMnNzs6AJMXFxs48K4RACpj8MIQZGFgwhAFdVA+FJbpRNAAAAAElFTkSuQmCC);
  right: 10px;
  left: auto; }

.Slider-navigation {
  position: absolute;
  top: calc(50% - 16px);
  left: 3%;
  width: 94%;
  height: 32px;
  z-index: 2;
  display: flex;
  justify-content: space-between; }
  .Slider-navigation .Slider-button--next,
  .Slider-navigation .Slider-button--prev {
    height: 32px;
    width: 18px;
    background-repeat: no-repeat; }
    .Slider-navigation .Slider-button--next:hover,
    .Slider-navigation .Slider-button--prev:hover {
      cursor: pointer; }

/* Slider: pagination */
.Slider-pagination {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 10;
  text-align: center;
  transform: translate3d(0, 0, 0); }
  .Slider-pagination.is-hidden {
    opacity: 0; }
  .Slider-pagination.is-locked {
    display: none; }

.Slider-pagination--fraction,
.Slider-pagination--custom,
.Slider--horizontal > .Slider-pagination--bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

.Slider-pagination--bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transform: scale(0.33);
    position: relative; }
  .Slider-pagination--bullets-dynamic .is-active {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-main {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-prev {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-prev-prev {
    transform: scale(0.33); }
  .Slider-pagination--bullets-dynamic .is-active-next {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-next-next {
    transform: scale(0.33); }

.Slider-pagination-item {
  height: 8px;
  width: 8px;
  background: #000000;
  border-radius: 100%;
  display: inline-block;
  opacity: 0.2; }
  button.Slider-pagination-item {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .is-clickable .Slider-pagination-item {
    cursor: pointer; }
  .Slider-pagination-item.is-active {
    background-color: currentColor;
    opacity: 1; }

.Slider--vertical > .Slider-pagination--bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .Slider--vertical > .Slider-pagination--bullets .Slider-pagination-item {
    display: block;
    margin-right: 6px;
    margin-left: 6px; }
  .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
      display: inline-block;
      transition-property: transform, top;
      transition-duration: 200ms; }

.Slider--horizontal > .Slider-pagination--bullets .Slider-pagination-item {
  margin: 0 4px; }

.Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transition-property: transform, left;
    transition-duration: 200ms; }

.Slider--horizontal.Slider--rtl > .Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transition-property: transform, right;
  transition-duration: 200ms; }

.Slider-pagination--progressbar {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .Slider-pagination--progressbar .Slider-pagination-fill {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    transform: scale(0);
    transform-origin: left top; }
  .Slider--rtl .Slider-pagination--progressbar .Slider-pagination-fill {
    transform-origin: right top; }
  .Slider--horizontal > .Slider-pagination--progressbar,
  .Slider--vertical > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 4px;
    width: 100%;
    left: 0;
    top: 0; }
  .Slider--vertical > .Slider-pagination--progressbar,
  .Slider--horizontal > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 100%;
    width: 4px;
    left: 0;
    top: 0; }

/* Slider: scrollbar */
.Slider-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none; }
  .Slider--horizontal > .Slider-scrollbar {
    height: 5px;
    width: 98%;
    position: absolute;
    bottom: 3px;
    left: 1%;
    z-index: 50; }
  .Slider--vertical > .Slider-scrollbar {
    height: 98%;
    width: 5px;
    position: absolute;
    top: 1%;
    right: 3px;
    z-index: 50; }
  .Slider-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px; }
  .Slider-scrollbar-cursor-drag {
    cursor: move; }
  .Slider-scrollbar .is-locked {
    display: none; }

/* Slider: thumbs */
/* Slider: zoom */
.Slider--zoom {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .Slider--zoom > img,
  .Slider--zoom > svg,
  .Slider--zoom > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }
  .Slider--zoom .is-zoomed {
    cursor: move; }

/* General */
.scrollmagic-pin-spacer {
  padding-bottom: 0 !important; }

.Test {
  border: 1px solid red; }

.gutter-sizer {
  width: 0 !important; }

.Parent-hidden {
  opacity: 0 !important;
  height: 0 !important; }

.Parent {
  height: auto;
  opacity: 1;
  transition: opacity .4s; }
  .Parent-title {
    font-size: 25px;
    line-height: 1.2;
    margin-bottom: 30px !important;
    font-weight: 500;
    color: #76b729;
    display: inline-block;
    padding-bottom: 5px;
    border-bottom: 2px solid #76b729; }

.Descandants {
  margin-bottom: 0px;
  font-size: 12px;
  position: relative;
  left: 3px; }
  .Dropdown-link .Descandants {
    left: -3px;
    font-size: 10px;
    bottom: 5px; }

.CalendarPage .Content {
  padding-bottom: 0; }

.Form-field,
input {
  border-radius: 0; }
  .Form-field:hover, .Form-field:focus,
  input:hover,
  input:focus {
    outline: none;
    box-shadow: none; }

.Month-item,
.Months-item {
  font-family: "robotoSlab", sans-serif !important; }

.Icon-down {
  overflow: hidden; }

html body {
  position: relative; }
  html body::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: #3f3e3e;
    z-index: -99999;
    transition: opacity .4s, visibility .4s;
    visibility: hidden;
    z-index: 3; }

.show-dropdown body::before {
  visibility: visible;
  opacity: .75; }

.CalendarPage .Months {
  margin-top: 80px;
  margin-bottom: 20px; }

.Months-title {
  color: #76b729;
  font-family: "adorn-condensed-sans", sans-serif !important;
  margin-bottom: 22px;
  font-size: 35px;
  line-height: 1.2;
  line-height: 39px;
  font-weight: normal;
  max-width: 258px;
  font-weight: 600; }

.Months-items {
  margin: 1px 0 0 1px;
  display: flex;
  flex-wrap: wrap; }

.Months-item {
  margin: -1px 0 0 -1px;
  padding: 12px 25px;
  font-size: 15px;
  color: #76b729;
  border: 1px solid #76b729;
  position: relative;
  text-decoration: none; }
  .CalendarPage .Months-item {
    width: 75px; }
  .Months-item::before {
    content: '';
    position: absolute;
    left: -4px;
    top: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    background-color: #76b729;
    transition: opacity .4s;
    z-index: -1;
    opacity: 0; }
  .CalendarPage .Months-item:hover {
    color: #ffffff; }
    .CalendarPage .Months-item:hover::before {
      opacity: 1; }
  .Months-item.no-hover {
    pointer-events: none; }
  .Months-item.is-active {
    background-color: #76b729;
    color: #ffffff !important;
    cursor: pointer; }
    .Months-item.is-active .Months-item {
      color: #ffffff; }
    .Months-item.is-active::before {
      opacity: 1; }

.Form {
  width: 100%; }
  @media screen and (max-width: 37.4375em) {
    .Form {
      margin-top: 60px; } }
  @media screen and (min-width: 37.5em) {
    .Form {
      max-width: 490px;
      margin-left: 20px; } }
  .Form-item--input {
    margin-bottom: 75px !important; }
  .Form-field {
    max-width: 100%;
    padding-bottom: 15px;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #2c2c2c; }
    .Form-field--checkbox {
      border: none; }

.DefaultPagePage {
  overflow-x: hidden; }

.DownloadsPagePage {
  background-color: #f2eeed; }
  .DownloadsPagePage .Intro {
    text-align: center;
    max-width: 500px;
    margin-top: 80px !important;
    margin: 0 auto; }
    .DownloadsPagePage .Intro .Title-line {
      font-family: "robotoSlab", sans-serif !important;
      text-transform: initial !important; }

@media screen and (min-width: 37.5em) {
  .ContactPage .Contact {
    display: flex;
    justify-content: space-between; } }

.ContactPage .Contact .Title-line {
  font-family: "robotoSlab", sans-serif !important;
  text-transform: initial; }

.ContactPage .Contact-item {
  color: #76b729;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 10px; }
  .ContactPage .Contact-item:hover {
    text-decoration: underline; }

.ContactPage .Contact-icon {
  margin-right: 30px; }

.ContactPage .Contact-nav {
  margin-top: 20px; }
  .ContactPage .Contact-nav p {
    margin-bottom: 0;
    font-size: 16px; }
  .ContactPage .Contact-nav .Nav--socialMedia {
    margin-left: 13.33333px; }
    .ContactPage .Contact-nav .Nav--socialMedia .Nav-icon * {
      fill: #76b729; }

.Downloads {
  max-width: 994px;
  margin: 40px auto; }
  .Downloads-item {
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding: 8px; }
    @media screen and (min-width: 37.5em) {
      .Downloads-item {
        padding: 8px;
        margin-bottom: 50px;
        display: flex;
        min-height: 240px; } }
  .Downloads-media {
    width: 100%;
    position: relative; }
    @media screen and (max-width: 37.4375em) {
      .Downloads-media {
        margin-bottom: 20px; } }
    @media screen and (min-width: 37.5em) {
      .Downloads-media img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover; } }
    @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
      .Downloads-media {
        max-width: 280px;
        min-width: 280px; } }
    @media screen and (min-width: 64em) {
      .Downloads-media {
        max-width: 360px;
        min-width: 360px; } }
    @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
      .Downloads-media {
        margin-right: 20px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Downloads-media {
        margin-right: 50px; } }
    @media screen and (min-width: 64em) {
      .Downloads-media {
        margin-right: 100px; } }
  .Downloads-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    .Downloads-content h2 {
      margin-bottom: 10px;
      font-size: 20px;
      color: #76b729;
      font-weight: normal; }

.Share {
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .Share-text {
    margin-bottom: 0px;
    font-size: 16px;
    margin: 0 5px; }
  .Share-link {
    margin: 0 5px; }
  .Share-icon {
    height: 20px;
    width: 20px; }
    .Share-icon * {
      fill: #76b729; }

.Pagination {
  text-align: center; }
  .Pagination-item {
    margin: 0 5px;
    border: 1px solid #76b729;
    color: #76b729;
    text-decoration: none;
    padding: 9px 12px;
    font-size: 13px;
    transition: background-color .4s, color .4s;
    display: inline-block;
    margin-bottom: 25px; }
    .Pagination-item.is-active, .Pagination-item:hover {
      background-color: #76b729;
      color: #ffffff; }
    .Pagination-item--medium {
      padding: 9px 15px; }
    .Pagination-item--large {
      padding: 9px 21px; }

.Month {
  margin-bottom: 20px; }
  .Month:first-of-type {
    margin-top: 40px; }
  .Month h1 {
    font-size: 28px !important; }
    .Month h1::before {
      left: 0; }
  .Month-wrapper {
    border: 1px solid #76b729;
    display: inline-flex;
    flex-wrap: wrap; }
  .Month-item {
    margin-bottom: 0px;
    font-size: 12px;
    padding: 11.5px;
    color: #76b729;
    line-height: normal !important; }
    .Month-item.is-active {
      position: relative;
      z-index: 2;
      color: #ffffff; }
      .Month-item.is-active::before {
        content: '';
        position: absolute;
        left: -1px;
        top: -7px;
        height: calc(100% + 14px);
        width: 100%;
        background-color: #76b729;
        z-index: -1; }

.CustomText em {
  font-style: normal;
  color: #76b729; }

.Title-line {
  color: #76b729;
  font-family: "adorn-condensed-sans", sans-serif !important;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  padding-bottom: 13.33333px; }
  @media screen and (max-width: 19.9375em) {
    .Title-line {
      font-size: 28px; } }
  @media screen and (min-width: 20em) and (max-width: 47.9375em) {
    .Title-line {
      font-size: calc(28px + 7 * ((100vw - 320px) / 448)); } }
  @media screen and (min-width: 48em) {
    .Title-line {
      font-size: 35px; } }
  .Title-line--big {
    font-weight: 600 !important; }
  .Title-line--left::before {
    left: 0 !important; }
  .Title-line::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 30px);
    width: 60px;
    height: 2px;
    background-color: #76b729; }

.HomepageBlock {
  margin-bottom: 120px; }

.AssortimentOverview {
  background-color: #f2eeed;
  padding-top: 80px !important;
  padding-bottom: 40px !important; }
  .AssortimentOverview-icon {
    height: 200px;
    width: 230px;
    position: absolute;
    right: 0px;
    z-index: 1; }
    @media screen and (min-width: 20em) and (max-width: 47.9375em) {
      .AssortimentOverview-icon {
        display: none; } }
    @media screen and (min-width: 85em) {
      .AssortimentOverview-icon {
        right: -20px; } }
  .AssortimentOverview-mediaWrapper {
    text-decoration: none;
    background-color: red;
    position: relative;
    background: url("../img//polaroid.png") no-repeat;
    background-size: cover;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 40px; }
    @media screen and (min-width: 48em) {
      .AssortimentOverview-mediaWrapper {
        margin-bottom: 40px; } }
  .AssortimentOverview-title {
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 25px;
    line-height: 1.2;
    color: #a83345;
    text-align: center;
    font-weight: normal; }
  .AssortimentOverview-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1046px;
    margin-left: auto;
    margin-right: auto; }
  .AssortimentOverview-item {
    width: 100%;
    margin-bottom: 40px; }
    .AssortimentOverview-item p {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
    @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
      .AssortimentOverview-item {
        max-width: calc((100% / 2) - 15px); } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .AssortimentOverview-item {
        max-width: calc((100% / 3) - 15px); } }
    @media screen and (min-width: 64em) {
      .AssortimentOverview-item {
        max-width: calc((100% / 3) - 35px); } }

.FindLocation {
  overflow: hidden;
  background-color: #a83345;
  color: #ffffff;
  padding-top: 100px !important;
  padding-bottom: 100px !important;
  z-index: 2; }
  .FindLocation-icon {
    position: absolute;
    z-index: -1; }
    @media screen and (max-width: 37.5em) {
      .FindLocation-icon {
        display: none; } }
    .FindLocation-icon--1 {
      top: 0;
      left: 100px; }
    .FindLocation-icon--2 {
      transform: scale(-1, 1);
      bottom: -10px;
      left: -50px; }
    .FindLocation-icon--3 {
      bottom: 0;
      right: 50px; }
  .FindLocation .Title-line {
    color: #ffffff;
    font-family: "robotoSlab", sans-serif !important;
    font-size: 28px;
    text-transform: inherit; }
    .FindLocation .Title-line::before {
      background-color: #ffffff; }
  .FindLocation-wrapper {
    max-width: 405px;
    margin: 0 auto; }
  .FindLocation-form {
    margin: 0 auto;
    max-width: 320px;
    width: 100%;
    display: flex; }
    .FindLocation-form--input {
      width: 100%;
      background-color: transparent;
      border: 1px solid #ffffff;
      box-shadow: none;
      padding: 14px; }
      .FindLocation-form--input, .FindLocation-form--input::placeholder {
        color: #ffffff;
        font-family: "fieldwork", sans-serif;
        font-size: 14px;
        text-align: center; }
    .FindLocation-form--button {
      background-color: #ffffff;
      width: 45px;
      min-width: 46px; }

.LazyLoad,
.LazyLoading {
  opacity: 0; }

.LazyLoaded {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  opacity: 1; }

/* Blocks */
/* Footer */
.Footer {
  background-color: #f2eeed;
  position: relative; }
  .DealerOverviewPagePage .Footer {
    z-index: 2; }
  .Footer-none .Footer::before {
    display: none; }
  .Footer--white,
  .Footer--white .DigitalPulse-object,
  .Footer-white .Footer,
  .Footer-white .Footer .DigitalPulse-object {
    background-color: #ffffff !important; }
  .Footer--white::before,
  .Footer-white .Footer::before {
    background-image: url("../img/icons/scratch-grey.svg") !important; }
  .Footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background-image: url("../img/icons/scratch.svg");
    background-repeat: no-repeat;
    transform: rotate(180deg);
    background-size: cover; }
  .Footer .Nav--socialMedia .Nav-link:hover .Nav-icon * {
    fill: #76b729; }
  .Footer .Nav--socialMedia .Nav-icon * {
    fill: #2c2c2c; }
  .Footer-top {
    padding-top: 160px;
    padding-bottom: 40px; }
    @media screen and (min-width: 28.75em) {
      .Footer-top {
        display: flex; } }
  .Footer-bottom {
    border-top: 1px solid #2c2c2c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px; }

/* DigitalPulse */
.DigitalPulse {
  color: #2c2c2c;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-left: auto;
  height: 1.5em; }
  .DigitalPulse-icon, .DigitalPulse-body {
    display: block;
    fill: #2c2c2c; }
  .DigitalPulse-label {
    margin-right: .3em;
    flex-shrink: 0;
    font-size: 12px;
    opacity: .6; }
  .DigitalPulse-object {
    background-color: #f2eeed;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .DigitalPulse-icon {
    flex-shrink: 0;
    height: 1.85743em;
    transform: translateY(5%);
    height: 25px;
    width: 20px;
    opacity: .6; }
  @media screen and (min-width: 64em) {
    .DigitalPulse-object {
      transition-property: transform, right;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      min-width: 5.56167em;
      position: relative;
      z-index: 1;
      position: absolute;
      top: 50%;
      right: 0;
      transform: scale(1.01) translateY(-50%); }
    .DigitalPulse:hover .DigitalPulse-object {
      color: #2c2c2c;
      right: 100%; }
      .DigitalPulse:hover .DigitalPulse-object .DigitalPulse-label, .DigitalPulse:hover .DigitalPulse-object .DigitalPulse-icon {
        opacity: 1; }
    .DigitalPulse-body {
      transition-property: width;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      margin-left: .3em;
      transform: translateY(12.5%);
      display: flex;
      justify-content: flex-end;
      height: 0.94828em;
      width: 5.56167em; } }

/* Print */
@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    letter-spacing: 0;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: " (" attr(href) ")"; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #9e9e9e;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  /**
     * General
     */
  html {
    font-family: sans-serif;
    font-size: 12pt; }
  .Container {
    max-width: none; }
  h1,
  h2,
  h3 {
    margin-bottom: 12pt;
    text-transform: none; }
  h1 {
    font-size: 24pt; }
  h2 {
    font-size: 18pt; }
  h3 {
    font-size: 14pt; } }

.Search-form {
  border-bottom: 1px solid #ffffff;
  max-width: 125px;
  display: flex;
  margin-right: 20px; }
  @media screen and (min-width: 37.5em) {
    .Search-form {
      margin-right: 40px; } }
  .Search-form--input {
    background-color: transparent;
    border: none;
    width: 100%; }
    .Search-form--input, .Search-form--input::placeholder {
      color: #ffffff;
      font-family: "robotoSlab", sans-serif;
      font-size: 14px; }
  .Search-form--icon {
    margin-bottom: 3px; }

.SearchResultsPage {
  background-color: #f2eeed; }
  .SearchResultsPage .SearchResults {
    max-width: 1197px;
    margin: 0 auto;
    margin-top: 100px; }
    .SearchResultsPage .SearchResults .Title-line {
      font-family: "robotoSlab", sans-serif !important;
      text-transform: initial !important; }
    .SearchResultsPage .SearchResults .Search-form {
      max-width: 210px;
      margin-left: auto;
      margin-right: 0;
      border-color: #76b729;
      padding-bottom: 10px; }
      .SearchResultsPage .SearchResults .Search-form .Search-form--input {
        opacity: .6; }
        .SearchResultsPage .SearchResults .Search-form .Search-form--input, .SearchResultsPage .SearchResults .Search-form .Search-form--input::placeholder {
          color: #2c2c2c;
          font-size: 20px;
          line-height: 38px;
          font-family: "fieldwork", sans-serif !important; }
      .SearchResultsPage .SearchResults .Search-form .Search-form--icon {
        height: 18px;
        width: 18px;
        opacity: .6; }
        .SearchResultsPage .SearchResults .Search-form .Search-form--icon * {
          stroke: #2c2c2c; }

.Results-item {
  border-bottom: 1px solid #76b729;
  margin-bottom: 20px;
  padding-bottom: 20px; }
  .Results-item:last-child {
    border: none; }

.Results-title {
  margin-bottom: 0px;
  font-size: 20px;
  color: #76b729;
  font-weight: normal; }

.Results-url {
  margin-bottom: 10px;
  font-size: 16px;
  color: #2c2c2c;
  opacity: .7;
  font-family: "fieldwork", sans-serif;
  line-height: 26px; }

.Results p {
  line-height: normal;
  color: #2c2c2c; }
  .Results p:last-child {
    margin-bottom: 0 !important; }

.Nav--main {
  position: relative;
  z-index: 1; }
  .Nav--main .Nav-item:not(:hover) .Dropdown {
    opacity: 0;
    visibility: hidden; }
  .Nav--main .Dropdown {
    margin: 0;
    padding: 40px 80px;
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    left: -40px;
    top: calc(100% + 10px);
    width: calc(100% + 80px);
    color: #ffffff;
    font-family: "robotoSlab", sans-serif;
    background-color: #f2eeed; }
    .Nav--main .Dropdown::before {
      content: "";
      height: 40px;
      transform: translateY(-100%);
      width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    .Nav--main .Dropdown-title {
      color: #76b729;
      font-size: 23px;
      line-height: 1.2;
      letter-spacing: 1.44px;
      font-family: "robotoSlab", sans-serif;
      text-transform: initial; }
    .Nav--main .Dropdown-items {
      columns: 2;
      flex-grow: 1;
      padding: 0;
      width: 100%;
      max-width: 75%; }
      @media screen and (min-width: 64em) {
        .Nav--main .Dropdown-items {
          columns: 3; } }
    .Nav--main .Dropdown-item {
      list-style: none;
      margin-bottom: 10px;
      font-size: 14px;
      letter-spacing: .88px; }
      .Nav--main .Dropdown-item--wrapper.MultipleCategories {
        break-inside: avoid;
        display: flex;
        flex-direction: column; }
    .Nav--main .Dropdown-link {
      color: #000000;
      text-decoration: none;
      text-transform: none; }
      .Nav--main .Dropdown-link:hover {
        color: #76b729; }
        .Nav--main .Dropdown-link:hover .Icon-down * {
          fill: #76b729; }
      .Nav--main .Dropdown-link .Icon-down {
        height: 7px;
        width: 7px;
        margin-left: -5px;
        margin-bottom: 4px; }
        .Nav--main .Dropdown-link .Icon-down * {
          transition: fill .4s;
          fill: #000000; }
    .Nav--main .Dropdown-spotlight {
      position: relative;
      max-height: 250px;
      display: flex;
      align-items: flex-end;
      border: 8px solid #76b729;
      text-decoration: none;
      z-index: 2;
      width: 100%;
      max-width: 25%; }
      @media screen and (min-width: 64em) {
        .Nav--main .Dropdown-spotlight {
          max-height: 450px; } }
      .Nav--main .Dropdown-spotlight--media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
        .Nav--main .Dropdown-spotlight--media img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .Nav--main .Dropdown-spotlight--content {
        text-align: center;
        margin-top: auto;
        z-index: 2;
        position: relative;
        background-color: #ffffff;
        padding: 10px;
        width: 100%; }
        .Nav--main .Dropdown-spotlight--content p {
          color: #2c2c2c;
          letter-spacing: .88px;
          margin-bottom: 0px;
          font-size: 14px;
          font-family: "robotoSlab", sans-serif; }
        .Nav--main .Dropdown-spotlight--content .Spotlight-title {
          color: #76b729; }
    .Nav--main .Dropdown-side {
      flex-shrink: 0; }
    .Nav--main .Dropdown-wrapper {
      display: flex;
      justify-content: space-between;
      min-height: 200px; }
      .Nav--main .Dropdown-wrapper--title {
        margin-bottom: 10px;
        font-size: 18px;
        color: #76b729;
        font-weight: 600;
        margin-top: 30px;
        text-transform: initial; }
        .Nav--main .Dropdown-wrapper--title:first-child {
          margin-top: 0; }
      .Nav--main .Dropdown-wrapper--items {
        margin-bottom: 30px; }

.Banner {
  background-color: #f2eeed;
  position: relative; }
  .Banner::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 75px;
    background-image: url("../img/icons/scratch.svg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 3; }
  @media screen and (min-width: 48em) {
    .Banner--large .Banner-media,
    .Banner--large .Banner-media img {
      max-height: 470px !important; } }
  @media screen and (min-width: 48em) {
    .Banner--small .Banner-media,
    .Banner--small .Banner-media img {
      max-height: 320px !important; } }
  @media screen and (min-width: 48em) {
    .Banner {
      display: grid;
      grid-template-columns: 55% 1fr;
      grid-template-rows: 100%; } }
  @media screen and (min-width: 48em) {
    .Banner-media {
      grid-column: 1/2;
      grid-row: 1/2;
      position: relative; }
      .Banner-media::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
        background-image: linear-gradient(to right, rgba(243, 239, 237, 0), #f2eeed 80%);
        z-index: 2; } }
  .Banner-media img {
    height: 100%;
    width: 100%;
    object-fit: cover; }
  @media screen and (max-width: 47.9375em) {
    .Banner-content {
      margin-left: 0px; } }
  @media screen and (min-width: 48em) and (max-width: 79.9375em) {
    .Banner-content {
      margin-left: calc(0px + 50 * ((100vw - 768px) / 512)); } }
  @media screen and (min-width: 80em) {
    .Banner-content {
      margin-left: 50px; } }
  @media screen and (max-width: 47.9375em) {
    .Banner-content {
      padding: 60px 20px 80px; } }
  @media screen and (min-width: 48em) {
    .Banner-content {
      grid-column: 2/3;
      grid-row: 1/2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 40px 0px; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .Banner-content {
      max-width: calc((45 / 100) * 1200px); } }
  @media screen and (min-width: 64em) {
    .Banner-content, .Banner-content p {
      max-width: 440px; } }
  .Banner-title {
    font-family: "robotoSlab", sans-serif;
    color: #76b729;
    font-weight: normal;
    position: relative;
    padding-bottom: 20px; }
    @media screen and (max-width: 19.9375em) {
      .Banner-title {
        font-size: 28px; } }
    @media screen and (min-width: 20em) and (max-width: 47.9375em) {
      .Banner-title {
        font-size: calc(28px + 7 * ((100vw - 320px) / 448)); } }
    @media screen and (min-width: 48em) {
      .Banner-title {
        font-size: 35px; } }
    @media screen and (min-width: 64em) {
      .Banner-title {
        max-width: 440px; } }
    .Banner-title::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 2px;
      background-color: #76b729; }
    .Banner-title--large {
      margin-bottom: 0;
      padding-bottom: 10px; }
      @media screen and (max-width: 19.9375em) {
        .Banner-title--large {
          font-size: 28px; } }
      @media screen and (min-width: 20em) and (max-width: 47.9375em) {
        .Banner-title--large {
          font-size: calc(28px + 17 * ((100vw - 320px) / 448)); } }
      @media screen and (min-width: 48em) {
        .Banner-title--large {
          font-size: 45px; } }
      .Banner-title--large::before {
        display: none; }
  .Banner .FindLocation-form {
    margin-right: auto;
    margin-left: 0; }
    .Banner .FindLocation-form--input {
      border-color: #a83345; }
      .Banner .FindLocation-form--input, .Banner .FindLocation-form--input::placeholder {
        color: #a83345; }
    .Banner .FindLocation-form--button {
      background-color: #a83345; }
    .Banner .FindLocation-form--icon * {
      stroke: #ffffff; }
  .Banner .Slider-pagination {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    top: 0;
    left: 0; }
    @media screen and (min-width: 80em) {
      .Banner .Slider-pagination {
        left: 10px; } }
    .Banner .Slider-pagination-item {
      margin: 9px !important;
      opacity: 1;
      background: transparent;
      border: 1px solid #ffffff;
      transition: background .3s; }
      .Banner .Slider-pagination-item.is-active, .Banner .Slider-pagination-item:hover {
        background-color: #ffffff; }

.HomePage .NewsOverview {
  padding-top: 80px !important;
  padding-bottom: 40px !important;
  position: relative; }
  .HomePage .NewsOverview::before, .HomePage .NewsOverview::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background-color: #f2eeed; }
  .HomePage .NewsOverview::before {
    background-image: url("../img/icons/background-min.jpg");
    background-blend-mode: multiply;
    opacity: .12;
    background-size: 250%;
    background-repeat: no-repeat; }
    @media screen and (min-width: 37.5em) {
      .HomePage .NewsOverview::before {
        background-size: cover; } }
  .HomePage .NewsOverview::after {
    z-index: -1; }
  .HomePage .NewsOverview-title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 1.2;
    color: #a83345;
    font-weight: normal; }
  .HomePage .NewsOverview-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto; }
  .HomePage .NewsOverview-item {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 40px;
    background-color: #ffffff;
    padding: 20px; }
    @media screen and (min-width: 48em) {
      .HomePage .NewsOverview-item {
        margin-bottom: 40px; } }
    @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
      .HomePage .NewsOverview-item {
        max-width: calc((100% / 2) - 15px); } }
    @media screen and (min-width: 48em) {
      .HomePage .NewsOverview-item {
        max-width: calc((100% / 3) - 15px); } }

.OverviewPagePage .NewsOverview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1075px;
  margin-left: auto;
  margin-right: auto; }
  .OverviewPagePage .NewsOverview-item {
    background-color: #ffffff;
    text-decoration: none;
    color: #2c2c2c;
    border: 8px solid #ffffff;
    display: flex;
    flex-direction: column; }
    .OverviewPagePage .NewsOverview-item h2 {
      font-weight: normal !important; }
    @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
      .OverviewPagePage .NewsOverview-item {
        max-width: calc((100% / 2) - 8px); } }
    @media screen and (min-width: 48em) {
      .OverviewPagePage .NewsOverview-item {
        max-width: calc((100% / 2) - 20px); } }
  .OverviewPagePage .NewsOverview-media {
    position: relative; }
  .OverviewPagePage .NewsOverview-date {
    position: absolute;
    right: 0;
    bottom: -1px;
    background-color: #ffffff;
    color: #76b729;
    font-size: 15px;
    padding: 8px; }
  .OverviewPagePage .NewsOverview-content {
    padding: 20px 10px;
    margin: auto 0px; }

.BlogContent {
  max-width: 1070px;
  margin: 0 auto; }

.MoreNews {
  padding-top: 80px !important;
  padding-bottom: 40px !important;
  position: relative; }
  .MoreNews::before, .MoreNews::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f2eeed; }
  .MoreNews::before {
    background-image: url("../img/icons/background-min.jpg");
    background-blend-mode: multiply;
    opacity: .12;
    background-size: 250%;
    background-repeat: no-repeat; }
    @media screen and (min-width: 37.5em) {
      .MoreNews::before {
        background-size: cover; } }
  .MoreNews::after {
    background-color: #f8f7f6;
    z-index: -1;
    opacity: .8; }
  .MoreNews .Title-line {
    font-family: "robotoSlab", sans-serif !important;
    text-transform: inherit;
    text-align: center; }
  .MoreNews-items {
    width: 100%;
    margin: 0 auto; }
    @media screen and (min-width: 37.5em) {
      .MoreNews-items {
        max-width: 555px;
        display: flex;
        justify-content: space-between; } }
  .MoreNews-item {
    text-decoration: none;
    width: 100%; }
    @media screen and (min-width: 37.5em) {
      .MoreNews-item {
        margin: 0 5px;
        max-width: 250px;
        display: flex;
        flex-direction: column; } }
    @media screen and (min-width: 37.5em) {
      .MoreNews-item--prev {
        text-align: right; } }
    @media screen and (min-width: 37.5em) {
      .MoreNews-item--next {
        margin-left: auto; } }
  .MoreNews-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #2c2c2c;
    font-weight: normal; }
  .MoreNews-text {
    margin-top: auto;
    color: #76b729;
    font-size: 12px; }

.RecipeOverview {
  overflow: hidden; }

.RecipesPage {
  background-color: #f2eeed; }

.RecipeGrid {
  max-width: 1100px;
  margin: 0 auto;
  position: relative; }
  .RecipeGrid:last-child {
    margin-bottom: 100px; }
  .RecipeGrid-icon {
    position: absolute;
    right: 0;
    top: -150px;
    right: -150px; }
  .RecipeGrid:nth-of-type(even) {
    direction: rtl; }
  @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
    .RecipeGrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr; } }
  @media screen and (min-width: 64em) {
    .RecipeGrid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr; } }
  .RecipeGrid-item {
    text-decoration: none;
    position: relative;
    min-height: 257px;
    margin: 7px;
    display: flex;
    align-items: flex-end;
    border: 1px solid transparent;
    overflow: hidden; }
    .RecipeGrid-item:hover img {
      transform: scale(1.1); }
    @media screen and (min-width: 37.5em) {
      .RecipeGrid-item:nth-of-type(1) {
        grid-column: 1/2;
        grid-row: 1/3; } }
    @media screen and (min-width: 37.5em) {
      .RecipeGrid-item:nth-of-type(2) {
        grid-column: 2/3;
        grid-row: 1/2; } }
    @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
      .RecipeGrid-item:nth-of-type(3) {
        grid-column: 2/3;
        grid-row: 2/3; } }
    @media screen and (min-width: 64em) {
      .RecipeGrid-item:nth-of-type(3) {
        grid-column: 3/4;
        grid-row: 1/2; } }
    @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
      .RecipeGrid-item:nth-of-type(4) {
        grid-column: 1/2;
        grid-row: 3/4; } }
    @media screen and (min-width: 64em) {
      .RecipeGrid-item:nth-of-type(4) {
        grid-column: 2/3;
        grid-row: 2/3; } }
    @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
      .RecipeGrid-item:nth-of-type(5) {
        grid-column: 2/3;
        grid-row: 3/4; } }
    @media screen and (min-width: 64em) {
      .RecipeGrid-item:nth-of-type(5) {
        grid-column: 3/4;
        grid-row: 2/3; } }
  .RecipeGrid-bg {
    display: block; }
    .RecipeGrid-bg, .RecipeGrid-bg::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
    .RecipeGrid-bg::before {
      content: '';
      background-image: linear-gradient(to bottom, rgba(216, 216, 216, 0), rgba(44, 44, 44, 0.75)); }
    .RecipeGrid-bg img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform .4s;
      z-index: -1; }
  .RecipeGrid-content {
    direction: ltr;
    z-index: 2;
    position: relative;
    color: #ffffff;
    margin-top: auto;
    padding: 25px;
    margin-right: auto; }
  .RecipeGrid-title {
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 1.2;
    font-weight: normal; }
  .RecipeGrid-cookItem {
    margin-bottom: 0px;
    font-size: 13px;
    font-family: "fieldwork", sans-serif;
    display: flex; }
    .RecipeGrid-cookItem--icon {
      height: 15px;
      width: 15px;
      margin-right: 5px; }

.Recipe-wrapper {
  padding: 100px 0px; }
  .Recipe-wrapper:first-of-type {
    padding-top: 0; }
  .Recipe-wrapper:nth-child(odd) {
    background-color: #ffffff; }
    .Recipe-wrapper:nth-child(odd) .Recipe {
      flex-direction: row-reverse; }
      .Recipe-wrapper:nth-child(odd) .Recipe-media {
        border-color: #f2eeed; }

.Recipe .Intro {
  margin-bottom: 30px; }
  .Recipe .Intro .RecipeGrid-cookTime {
    margin-right: 30px; }
  .Recipe .Intro .RecipeGrid-cookItem {
    margin-bottom: 15px; }
    .Recipe .Intro .RecipeGrid-cookItem--icon * {
      stroke: #2c2c2c; }

.Recipe-title {
  font-family: "robotoSlab", sans-serif !important;
  font-size: 25px;
  text-transform: initial;
  color: #76b729 !important; }

.Recipe ul li {
  line-height: 29px;
  position: relative;
  list-style: none; }
  .Recipe ul li::before {
    content: "";
    position: absolute;
    left: calc((20px + 3px) * -1);
    top: calc(50% - 3px);
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #76b729; }

.Related h1 {
  text-align: center; }

.Related .Title-line {
  font-family: "robotoSlab", sans-serif !important;
  text-transform: initial;
  font-size: 30px; }

.Related .Assortiment-items {
  justify-content: center; }

.OverviewPagePage,
.AssortimentOverviewPagePage {
  background-color: #f2eeed; }

.AssortimentOverviewPagePage .Months-item {
  width: auto !important; }

.Overview-assortiment {
  margin: 0 auto;
  max-width: 1080px; }
  .Overview-assortiment .Intro {
    margin: 40px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media screen and (min-width: 48em) {
      .Overview-assortiment .Intro {
        margin: 80px 0px; } }
    .Overview-assortiment .Intro h1 {
      color: #76b729;
      font-size: 35px;
      max-width: 410px;
      width: 100%;
      font-weight: normal; }
    .Overview-assortiment .Intro .CustomText {
      width: 100%;
      max-width: 457px; }

.Assortiment-fullWidth {
  visibility: hidden;
  opacity: 0;
  display: none;
  justify-content: space-between;
  height: auto; }
  .Assortiment-fullWidth .Months {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 145px; }
    @media screen and (max-width: 47.9375em) {
      .Assortiment-fullWidth .Months {
        margin-bottom: 20px; } }
    @media screen and (max-width: 63.9375em) {
      .Assortiment-fullWidth .Months {
        margin-right: 10px; } }
    .Assortiment-fullWidth .Months-items {
      display: flex;
      align-items: center; }
      .Assortiment-fullWidth .Months-items:first-child {
        margin-bottom: 20px; }
      .Assortiment-fullWidth .Months-items h3 {
        margin: 0;
        padding: 0;
        font-family: "adorn-condensed-sans", sans-serif;
        font-weight: normal;
        font-size: 20px;
        margin-right: 15px;
        font-weight: 600; }
        @media screen and (min-width: 64em) {
          .Assortiment-fullWidth .Months-items h3 {
            margin-right: 30px; } }
    .Assortiment-fullWidth .Months-item {
      margin-bottom: 0px;
      font-size: 12px;
      border: 1px solid #76b729;
      padding: 12px 11px;
      color: #76b729;
      font-family: "robotoSlab", sans-serif !important; }
      @media screen and (max-width: 64em) {
        .Assortiment-fullWidth .Months-item {
          display: none; } }
      .Assortiment-fullWidth .Months-item.is-active {
        display: block;
        background-color: #76b729;
        color: #ffffff;
        position: relative;
        z-index: 2; }
        .Assortiment-fullWidth .Months-item.is-active::before {
          content: '';
          position: absolute;
          left: -2px;
          top: -5px;
          height: calc(100% + 10px);
          width: calc(100% + 4px);
          background-color: #76b729;
          z-index: -1; }
  .Assortiment-fullWidth .Info {
    width: 100%;
    max-width: 270px;
    color: #2c2c2c; }
    .Assortiment-fullWidth .Info h4 {
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: normal; }
    .Assortiment-fullWidth .Info p {
      margin-bottom: 10px;
      font-size: 16px; }
    .Assortiment-fullWidth .Info .Button {
      margin-bottom: 0; }

.Assortiment-Wrapper {
  max-width: 1080px;
  margin: 0 auto; }

.Assortiment-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .Assortiment-items.Overlay {
    overflow: hidden; }
    .Assortiment-items.Overlay:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f2eeed;
      z-index: 3;
      background-image: url(http://www.broadwaybalancesamerica.com/images/ajax-loader.gif);
      background-repeat: no-repeat;
      background-position: top center; }

.Assortiment-item {
  margin-bottom: 40px;
  text-decoration: none;
  flex-direction: column; }
  @media screen and (min-width: 48em) {
    .Assortiment-item {
      margin-bottom: 50px; } }
  .Assortiment-item--filter {
    display: block;
    width: 100%; }
    @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
      .Assortiment-item--filter {
        width: calc((100% - 30px) / 2); } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Assortiment-item--filter {
        width: calc((100% - (30px * 2)) / 3); } }
    @media screen and (min-width: 64em) {
      .Assortiment-item--filter {
        width: calc((100% - (30px * 3)) / 4); } }
  .Assortiment-item h3 {
    color: #76b729;
    font-family: "robotoSlab", sans-serif;
    font-weight: normal;
    margin-bottom: 0px;
    font-size: 23px;
    line-height: 1.2;
    margin-top: auto;
    padding-top: 20px; }
  @media screen and (min-width: 37.5em) {
    .Assortiment-item.FullWidth {
      display: flex;
      flex-direction: row;
      width: 100% !important; }
      .Assortiment-item.FullWidth .Assortiment-content {
        width: 100%;
        background-color: #ffffff;
        padding: 20px 10px; } }
    @media screen and (min-width: 37.5em) and (min-width: 64em) {
      .Assortiment-item.FullWidth .Assortiment-content {
        padding: 20px 40px; } }
  @media screen and (min-width: 37.5em) {
      .Assortiment-item.FullWidth .Assortiment-fullWidth {
        visibility: visible;
        opacity: 1;
        display: block;
        margin-top: 40px; } }
    @media screen and (min-width: 37.5em) and (min-width: 48em) {
      .Assortiment-item.FullWidth .Assortiment-fullWidth {
        display: flex; } }
  @media screen and (min-width: 37.5em) {
      .Assortiment-item.FullWidth .Assortiment-media {
        max-width: 275px;
        width: 100%; }
        .Assortiment-item.FullWidth .Assortiment-media img {
          object-fit: cover;
          height: 100%;
          width: 100%; } }

.Assortiment-media {
  display: block;
  border: 13px solid #ffffff; }

.Assortiment-search {
  margin-bottom: 50px; }
  @media screen and (min-width: 37.5em) {
    .Assortiment-search {
      display: flex;
      justify-content: space-between; } }
  .Assortiment-search .Layout {
    display: flex;
    max-width: 65px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    @media screen and (max-width: 37.4375em) {
      .Assortiment-search .Layout {
        display: none; } }
    .Assortiment-search .Layout::before {
      position: absolute;
      content: '';
      height: 17px;
      width: 1px;
      background-color: #76b729;
      left: calc((65px / 2) - (1px / 2));
      top: 0; }
      @media screen and (min-width: 37.5em) {
        .Assortiment-search .Layout::before {
          top: calc((50px - 17px) / 2); } }
    .Assortiment-search .Layout-icon, .Assortiment-search .Layout-item {
      height: 17px;
      width: 17px;
      display: block; }

@media screen and (min-width: 37.5em) {
  .Assortiment-filters {
    display: flex;
    flex-grow: 1;
    align-items: center; } }

@media screen and (max-width: 37.4375em) {
  .Assortiment-filters {
    margin-bottom: 20px; } }

.Assortiment-filters--title {
  margin-bottom: 0px;
  font-size: 18px;
  color: #76b729;
  margin-right: 10px;
  min-width: 100px; }

.Assortiment-filters .Kenmerken {
  min-width: 166px;
  position: relative; }
  @media screen and (max-width: 37.4375em) {
    .Assortiment-filters .Kenmerken {
      margin: 20px 0px; } }
  .Assortiment-filters .Kenmerken.is-active .Dropdown-togle .Icon-down {
    transform: rotate(180deg); }
  .Assortiment-filters .Kenmerken.is-active .Dropdown {
    z-index: 2;
    opacity: 1;
    visibility: visible; }

.Assortiment-filters .Dropdown-togle {
  height: 50px;
  width: 100%;
  padding: 15px 20px;
  font-size: 13px;
  border: 1px solid #76b729;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #76b729;
  text-decoration: none; }
  .Assortiment-filters .Dropdown-togle .Icon-down {
    height: 10px;
    width: 10px;
    transition: transform .3s; }
    .Assortiment-filters .Dropdown-togle .Icon-down * {
      fill: #76b729; }

.Assortiment-filters .Dropdown {
  position: absolute;
  background-color: #f7f7f7;
  width: 100%;
  border: 1px solid #76b729;
  top: calc(100% + 10px);
  padding: 20px;
  max-height: 182px;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s; }
  .Assortiment-filters .Dropdown::-webkit-scrollbar {
    width: 8px; }
  .Assortiment-filters .Dropdown::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #76b729;
    border: solid 3px transparent; }
  .Assortiment-filters .Dropdown-item {
    text-decoration: none;
    display: flex;
    margin-bottom: 15px;
    font-size: 13px;
    color: #76b729;
    align-items: center; }
    .Assortiment-filters .Dropdown-item .Count {
      color: #2c2c2c;
      font-size: 10px;
      margin-left: 5px;
      font-weight: 300 !important; }
    .Assortiment-filters .Dropdown-item:hover, .Assortiment-filters .Dropdown-item.is-active {
      font-weight: bold; }
    .Assortiment-filters .Dropdown-item:last-child {
      margin-bottom: 0; }

.Assortiment-input {
  min-width: 166px;
  height: 50px;
  border: 1px solid #76b729;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px; }
  @media screen and (max-width: 37.4375em) {
    .Assortiment-input {
      width: 100%; } }
  @media screen and (min-width: 37.5em) {
    .Assortiment-input {
      margin-left: 10px;
      max-width: 166px; } }
  .Assortiment-input--item {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #76b729; }
    .Assortiment-input--item, .Assortiment-input--item::placeholder {
      color: #76b729;
      font-size: 13px; }

.Related {
  padding: 80px 0px;
  background-color: #f2eeed; }

.AssortimentBanner h1,
.Recipe h1 {
  color: #76b729;
  font-weight: normal;
  margin-bottom: 20px;
  font-size: 35px;
  line-height: 1.2; }

.AssortimentBanner h2,
.Recipe h2 {
  color: #2c2c2c;
  font-weight: normal;
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 1.2; }

.AssortimentBanner p,
.Recipe p {
  line-height: 26px; }

@media screen and (min-width: 48em) {
  .AssortimentBanner,
  .Recipe {
    display: flex;
    justify-content: space-between; } }

.AssortimentBanner-wrapper,
.Recipe-wrapper {
  background-color: #f2eeed; }

.AssortimentBanner-media,
.Recipe-media {
  width: 100%;
  border: 8px solid #ffffff;
  height: 100%; }
  @media screen and (max-width: 47.9375em) {
    .AssortimentBanner-media,
    .Recipe-media {
      margin-bottom: 40px; } }
  @media screen and (min-width: 48em) {
    .AssortimentBanner-media,
    .Recipe-media {
      max-width: 480px;
      margin-left: auto; } }
  .AssortimentBanner-media .Download,
  .Recipe-media .Download {
    position: absolute;
    bottom: -1px;
    right: 0;
    z-index: 2;
    padding: 12px 10px 12px 15px;
    background-color: #ffffff; }
    .AssortimentBanner-media .Download:hover .Download-link,
    .Recipe-media .Download:hover .Download-link {
      text-decoration: underline; }
    .AssortimentBanner-media .Download::before,
    .Recipe-media .Download::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 45px;
      background-color: #76b729;
      z-index: -1; }
    .AssortimentBanner-media .Download-link,
    .Recipe-media .Download-link {
      color: #76b729;
      text-decoration: none;
      display: flex;
      align-items: center; }
    .AssortimentBanner-media .Download-icon,
    .Recipe-media .Download-icon {
      width: 25px;
      margin-left: 20px; }

.AssortimentBanner-content,
.Recipe-content {
  max-width: 550px;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .AssortimentBanner-content,
    .Recipe-content {
      margin-left: 80px; } }

.AssortimentBanner .Label,
.Recipe .Label {
  font-family: "robotoSlab", sans-serif;
  position: absolute;
  top: 75px;
  left: 115px;
  z-index: 2;
  padding: 12px 10px 12px 15px;
  background-color: #76b729;
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 12px; }

@media screen and (max-width: 79.9375em) and (max-width: 63.9375em) {
  .AssortimentBanner .Label,
  .Recipe .Label {
    left: 10px; } }

@media screen and (max-width: 79.9375em) and (min-width: 64em) and (max-width: 79.9375em) {
  .AssortimentBanner .Label,
  .Recipe .Label {
    left: calc(10px + 30 * ((100vw - 1024px) / 256)); } }

@media screen and (max-width: 79.9375em) and (min-width: 80em) {
  .AssortimentBanner .Label,
  .Recipe .Label {
    left: 40px; } }

.Tab-content,
.Tab-media {
  visibility: hidden; }
  .Tab-content, .Tab-content *,
  .Tab-media,
  .Tab-media * {
    height: 0; }
  .Tab-content.is-active,
  .Tab-media.is-active {
    visibility: visible; }
    .Tab-content.is-active, .Tab-content.is-active *,
    .Tab-media.is-active,
    .Tab-media.is-active * {
      height: auto; }

@media screen and (min-width: 48em) {
  .Tabs {
    display: flex; } }

.Tabs-wrapper .Title-line {
  font-family: "robotoSlab", sans-serif !important;
  font-size: 25px;
  text-transform: initial; }

.Tabs-titles {
  margin: 1px 0 0 1px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px; }
  .Tabs-titles .Tab-title {
    margin: -1px 0 0 -1px;
    border: 1px solid #76b729;
    width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    font-size: 15px;
    color: #76b729;
    text-align: center;
    padding: 15px 5px;
    font-weight: normal;
    position: relative;
    transition: color .4s; }
    @media screen and (min-width: 25em) and (max-width: 37.4375em) {
      .Tabs-titles .Tab-title {
        max-width: calc(100% / 2); } }
    @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
      .Tabs-titles .Tab-title {
        max-width: calc(100% / 3); } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Tabs-titles .Tab-title {
        max-width: calc(100% / 2); } }
    @media screen and (min-width: 64em) {
      .Tabs-titles .Tab-title {
        max-width: calc(100% / 3); } }
    .Tabs-titles .Tab-title::before {
      content: '';
      position: absolute;
      top: -6px;
      left: -4px;
      width: calc(100% + 8px);
      height: calc(100% + 12px);
      background-color: #76b729;
      z-index: -1;
      opacity: 0;
      transition: opacity .4s; }
    .Tabs-titles .Tab-title.is-active, .Tabs-titles .Tab-title:hover {
      cursor: pointer;
      color: #ffffff; }
      .Tabs-titles .Tab-title.is-active::before, .Tabs-titles .Tab-title:hover::before {
        opacity: 1; }

.Tabs-content--wrapper {
  width: 100%; }
  @media screen and (min-width: 48em) {
    .Tabs-content--wrapper {
      max-width: 575px;
      margin-right: 70px; } }

.Tabs .Tab-alart {
  font-size: 20px;
  color: #a83345;
  font-weight: normal; }

.Tabs .Tabs-media--wrapper {
  border: 8px solid #f2eeed;
  height: 100%; }
  @media screen and (min-width: 48em) {
    .Tabs .Tabs-media--wrapper {
      margin-top: -30px; } }
  @media screen and (min-width: 48em) and (max-width: 47.9375em) {
    .Tabs .Tabs-media--wrapper {
      max-width: 300px; } }
  @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 79.9375em) {
    .Tabs .Tabs-media--wrapper {
      max-width: calc(300px + 188 * ((100vw - 768px) / 512)); } }
  @media screen and (min-width: 48em) and (min-width: 80em) {
    .Tabs .Tabs-media--wrapper {
      max-width: 488px; } }

.OverviewPagePage .Main {
  overflow-y: hidden; }

.OverviewPagePage .Overview-intro {
  max-width: 604px;
  text-align: center;
  margin: 60px auto 100px auto; }
  .OverviewPagePage .Overview-intro .Title-line {
    font-family: "robotoSlab", sans-serif !important;
    text-transform: initial; }
  .OverviewPagePage .Overview-intro .CustomText {
    display: block;
    max-width: 500px;
    margin: 0px auto; }

.ContentBlock {
  width: 100%;
  max-width: 785px;
  margin: 0 auto; }
  .ContentBlock-typeVideo, .ContentBlock-typeMedia {
    margin: 60px auto; }
    @media screen and (min-width: 48em) {
      .ContentBlock-typeVideo, .ContentBlock-typeMedia {
        margin: 100px auto; } }
  .ContentBlock--large {
    max-width: 990px !important; }
  .ContentBlock-media {
    margin: 20px auto; }
    @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
      .ContentBlock-media {
        margin: 10px auto; } }
    @media screen and (min-width: 48em) {
      .ContentBlock-media {
        margin: 20px auto; } }
    @media screen and (min-width: 37.5em) {
      .ContentBlock-media img {
        max-height: 570px; } }
  @media screen and (min-width: 37.5em) {
    .ContentBlock-typeMedia {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .ContentBlock-typeMedia--half .ContentBlock-media {
    width: 100%; }
    @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
      .ContentBlock-typeMedia--half .ContentBlock-media {
        max-width: calc((100% / 2) - 20px); } }
    @media screen and (min-width: 48em) {
      .ContentBlock-typeMedia--half .ContentBlock-media {
        max-width: calc((100% / 2) - 40px); } }
  .ContentBlock-typeVideo .Thumbnail {
    position: relative; }
    .ContentBlock-typeVideo .Thumbnail::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      transition: background-color .6s; }
    .ContentBlock-typeVideo .Thumbnail:hover {
      cursor: pointer; }
      .ContentBlock-typeVideo .Thumbnail:hover::before {
        background-color: rgba(0, 0, 0, 0); }
      .ContentBlock-typeVideo .Thumbnail:hover .Play-icon {
        transform: scale(1.1); }
    .ContentBlock-typeVideo .Thumbnail-icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .ContentBlock-typeVideo .Thumbnail .Play-icon {
      height: 102px;
      width: 102px;
      transition: transform .4s; }
  .ContentBlock-heading1 {
    color: #76b729; }
    @media screen and (max-width: 19.9375em) {
      .ContentBlock-heading1 {
        font-size: 30px; } }
    @media screen and (min-width: 20em) and (max-width: 47.9375em) {
      .ContentBlock-heading1 {
        font-size: calc(30px + 6 * ((100vw - 320px) / 448)); } }
    @media screen and (min-width: 48em) {
      .ContentBlock-heading1 {
        font-size: 36px; } }
  @media screen and (max-width: 19.9375em) {
    .ContentBlock-heading2 {
      font-size: 25px; } }
  @media screen and (min-width: 20em) and (max-width: 47.9375em) {
    .ContentBlock-heading2 {
      font-size: calc(25px + 5 * ((100vw - 320px) / 448)); } }
  @media screen and (min-width: 48em) {
    .ContentBlock-heading2 {
      font-size: 30px; } }
  @media screen and (max-width: 19.9375em) {
    .ContentBlock-heading3 {
      font-size: 20px; } }
  @media screen and (min-width: 20em) and (max-width: 47.9375em) {
    .ContentBlock-heading3 {
      font-size: calc(20px + 5 * ((100vw - 320px) / 448)); } }
  @media screen and (min-width: 48em) {
    .ContentBlock-heading3 {
      font-size: 25px; } }
  .ContentBlock-heading1, .ContentBlock-heading2, .ContentBlock-heading3 {
    font-weight: normal;
    margin-bottom: 25px; }
  .ContentBlock-typeText {
    line-height: 26px; }
  .ContentBlock-typeButton {
    margin: 25px auto; }
  .ContentBlock.typeMediaBlock {
    max-width: 1060px !important;
    padding: 40px 0px; }
    @media screen and (min-width: 48em) {
      .ContentBlock.typeMediaBlock {
        padding: 100px 0px; } }
    @media screen and (min-width: 48em) {
      .ContentBlock.typeMediaBlock {
        display: flex;
        justify-content: space-between; }
        .ContentBlock.typeMediaBlock .typeMediaBlock-content {
          margin-left: 20px; }
        .ContentBlock.typeMediaBlock--reverse {
          flex-direction: row-reverse; }
          .ContentBlock.typeMediaBlock--reverse .typeMediaBlock-content {
            margin-right: 20px;
            margin-left: 0; } }
    .ContentBlock.typeMediaBlock--bg {
      position: relative; }
      .ContentBlock.typeMediaBlock--bg::before {
        position: absolute;
        content: '';
        top: 0;
        left: calc(((100vw - (100%)) / 2) * -1);
        width: 100vw;
        height: 100%;
        background-color: #f2eeed;
        z-index: -1; }
      .ContentBlock.typeMediaBlock--bg .typeMediaBlock-media {
        border: 8px solid #ffffff; }
  .ContentBlock .typeMediaBlock-media {
    height: 100%; }
    @media screen and (max-width: 47.9375em) {
      .ContentBlock .typeMediaBlock-media {
        margin-bottom: 20px; } }
    @media screen and (min-width: 48em) {
      .ContentBlock .typeMediaBlock-media {
        max-width: 488px; } }
  .ContentBlock .typeMediaBlock-content {
    width: 100%; }
    .ContentBlock .typeMediaBlock-content p {
      line-height: normal; }
    @media screen and (min-width: 48em) {
      .ContentBlock .typeMediaBlock-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; }
        .ContentBlock .typeMediaBlock-content,
        .ContentBlock .typeMediaBlock-content p {
          max-width: 500px; } }
  .ContentBlock .typeMediaBlock-heading1, .ContentBlock .typeMediaBlock-heading2, .ContentBlock .typeMediaBlock-ondertitel {
    font-weight: normal; }
  .ContentBlock .typeMediaBlock-heading1 {
    margin-bottom: 13px;
    font-size: 35px;
    line-height: 1.2;
    color: #76b729; }
  .ContentBlock .typeMediaBlock-heading2 {
    margin-bottom: 14px;
    font-size: 20px;
    color: #76b729; }
  .ContentBlock .typeMediaBlock-ondertitel {
    margin-bottom: 20px;
    font-size: 18px; }
  .ContentBlock-typeTabs {
    margin-top: 100px;
    max-width: 1077px !important; }
    .CalendarPage .ContentBlock-typeTabs {
      margin-top: 60px; }
    .ContentBlock-typeTabs .typeTabs-wrapper {
      visibility: hidden;
      height: 0;
      position: relative; }
      .ContentBlock-typeTabs .typeTabs-wrapper::before {
        position: absolute;
        content: '';
        top: 0;
        left: calc(((100vw - (100% - 15px)) / 2) * -1);
        width: 100vw;
        height: 100%;
        background-color: #f2eeed;
        z-index: -1; }
      .ContentBlock-typeTabs .typeTabs-wrapper .typeTabs-media {
        visibility: hidden;
        height: 0;
        width: 0;
        position: absolute; }
      .ContentBlock-typeTabs .typeTabs-wrapper.is-active {
        padding: 80px 0px;
        height: auto;
        visibility: visible; }
        @media screen and (min-width: 48em) {
          .ContentBlock-typeTabs .typeTabs-wrapper.is-active {
            display: flex; } }
        .ContentBlock-typeTabs .typeTabs-wrapper.is-active .typeTabs-media.is-active {
          position: relative;
          width: auto;
          height: 100%;
          visibility: visible; }
    .ContentBlock-typeTabs .typeTabs-media {
      margin-left: auto;
      border: 6px solid #ffffff;
      height: 100%; }
      @media screen and (min-width: 48em) {
        .ContentBlock-typeTabs .typeTabs-media {
          max-width: 488px !important; }
          .ContentBlock-typeTabs .typeTabs-media img {
            max-height: 410px !important; } }
    .ContentBlock-typeTabs .typeTabs-titles {
      margin: 1px 0 0 1px;
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 47.9375em) {
        .ContentBlock-typeTabs .typeTabs-titles {
          justify-content: center; } }
    .ContentBlock-typeTabs .typeTabs-title {
      margin: -1px 0 0 -1px;
      text-decoration: none;
      border: 1px solid #76b729;
      padding: 15px 5px;
      display: block;
      text-align: center;
      color: #76b729;
      transition: background-color .4s, color .4s;
      font-family: "robotoSlab", sans-serif; }
      @media screen and (max-width: 37.4375em) {
        .ContentBlock-typeTabs .typeTabs-title {
          width: auto;
          min-width: 125px; } }
      @media screen and (min-width: 37.5em) {
        .ContentBlock-typeTabs .typeTabs-title {
          width: 100%;
          max-width: 200px; } }
      .ContentBlock-typeTabs .typeTabs-title:hover, .ContentBlock-typeTabs .typeTabs-title.is-active {
        background-color: #76b729;
        color: #ffffff; }
    .ContentBlock-typeTabs .TabContent {
      width: 100%; }
      @media screen and (min-width: 48em) {
        .ContentBlock-typeTabs .TabContent {
          margin-right: 20px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .ContentBlock-typeTabs .TabContent {
          max-width: 327px; } }
      @media screen and (min-width: 64em) {
        .ContentBlock-typeTabs .TabContent {
          max-width: 497px; } }
      .ContentBlock-typeTabs .TabContent-item {
        margin-bottom: 40px; }
      .ContentBlock-typeTabs .TabContent-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        font-size: 20px;
        padding-bottom: 13px;
        border-bottom: 2px solid #76b729;
        font-weight: normal;
        align-items: center;
        color: #76b729; }
        .ContentBlock-typeTabs .TabContent-title:hover, .ContentBlock-typeTabs .TabContent-title.is-active {
          cursor: pointer; }
          .ContentBlock-typeTabs .TabContent-title:hover .TabContent-icon #Group rect, .ContentBlock-typeTabs .TabContent-title.is-active .TabContent-icon #Group rect {
            transform: rotate(90deg); }
          .ContentBlock-typeTabs .TabContent-title:hover .TabContent-icon #Group polygon, .ContentBlock-typeTabs .TabContent-title.is-active .TabContent-icon #Group polygon {
            opacity: 0; }
      .ContentBlock-typeTabs .TabContent-icon {
        height: 8px;
        width: 8px; }
        .ContentBlock-typeTabs .TabContent-icon * {
          fill: #76b729; }
          .ContentBlock-typeTabs .TabContent-icon * #Group rect {
            transition: transform .3s;
            transform-origin: 50% 50%; }
          .ContentBlock-typeTabs .TabContent-icon * #Group polygon {
            transition: opacity .3s; }
      .ContentBlock-typeTabs .TabContent-text {
        max-width: 500px;
        display: none; }

.MultiTabs {
  margin-top: 100px;
  max-width: 1077px !important; }
  .CalendarPage .MultiTabs {
    margin-top: 60px; }
  .MultiTabs-titles {
    margin: 1px 0 0 1px;
    display: flex;
    flex-wrap: wrap; }
    .MultiTabs-titles--item {
      color: #76b729;
      width: 100%;
      max-width: 200px;
      margin: -1px 0 0 -1px;
      text-decoration: none;
      border: 1px solid #76b729;
      padding: 15px 5px;
      display: block;
      text-align: center;
      transition: background-color .4s, color .4s;
      cursor: pointer; }
      .MultiTabs-titles--item.is-active, .MultiTabs-titles--item:hover {
        background-color: #76b729;
        color: #fff; }
  .MultiTabs .MultiTabs-background {
    position: relative; }
    .MultiTabs .MultiTabs-background::before {
      position: absolute;
      content: '';
      top: 0;
      left: calc(((100vw - (100% - 15px)) / 2) * -1);
      width: 100vw;
      height: 100%;
      background-color: #f2eeed;
      z-index: -1; }
  .MultiTabs-contentWrapper {
    height: 0;
    opacity: 0; }
    .MultiTabs-contentWrapper .MultiTabs-content {
      display: none; }
    .MultiTabs-contentWrapper.is-active {
      opacity: 1;
      height: auto; }
      .MultiTabs-contentWrapper.is-active .MultiTabs-content {
        display: flex; }
  .MultiTabs-content {
    padding: 80px 0px; }
    @media screen and (max-width: 47.9375em) {
      .MultiTabs-content {
        display: block !important; } }
    @media screen and (min-width: 48em) {
      .MultiTabs-content {
        display: flex; } }
    .MultiTabs-content--inhoud {
      width: 100%; }
      @media screen and (min-width: 48em) {
        .MultiTabs-content--inhoud {
          margin-right: 40px; } }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .MultiTabs-content--inhoud {
          max-width: 327px; } }
      @media screen and (min-width: 64em) {
        .MultiTabs-content--inhoud {
          max-width: 497px; } }
      .MultiTabs-content--inhoud .Content {
        margin-bottom: 40px; }
        .MultiTabs-content--inhoud .Content.is-active h3 .TabContent-icon #Group rect, .MultiTabs-content--inhoud .Content:hover h3 .TabContent-icon #Group rect {
          transform: rotate(90deg); }
        .MultiTabs-content--inhoud .Content.is-active h3 .TabContent-icon #Group polygon, .MultiTabs-content--inhoud .Content:hover h3 .TabContent-icon #Group polygon {
          opacity: 0; }
        .MultiTabs-content--inhoud .Content h3 {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          font-size: 20px;
          padding-bottom: 13px;
          border-bottom: 2px solid #76b729;
          font-weight: normal;
          align-items: center;
          color: #76b729;
          cursor: pointer; }
        .MultiTabs-content--inhoud .Content span {
          display: none; }
          .MultiTabs-content--inhoud .Content span p {
            max-width: 500px; }
        .MultiTabs-content--inhoud .Content.active span {
          display: block; }
    .MultiTabs-content--mediaWrapper {
      width: 100%; }
      @media screen and (min-width: 48em) {
        .MultiTabs-content--mediaWrapper {
          max-width: 488px;
          margin-left: auto; } }
    .MultiTabs-content--media {
      border: 6px solid #ffffff;
      height: auto;
      top: 0;
      z-index: 0;
      opacity: 0; }
      .MultiTabs-content--media,
      .MultiTabs-content--media * {
        height: 0;
        border: none; }
      .MultiTabs-content--media.is-active {
        opacity: 1;
        border: 6px solid #ffffff; }
        .MultiTabs-content--media.is-active,
        .MultiTabs-content--media.is-active * {
          height: auto; }

.GoogleMaps {
  width: 100%;
  height: 100%; }

.MarkerInfo {
  max-width: 205px;
  padding: 20px;
  height: auto; }
  .MarkerInfo-title {
    font-weight: normal;
    margin-bottom: 5px;
    font-size: 15px;
    color: #76b729; }
  .MarkerInfo-title, .MarkerInfo-address, .MarkerInfo-link {
    line-height: 15px; }
  .MarkerInfo-address, .MarkerInfo-link {
    margin-bottom: 5px;
    font-size: 12px;
    color: #2c2c2c;
    font-family: "fieldwork", sans-serif; }
  .MarkerInfo-link {
    text-decoration: underline; }

.NoDealers h1 {
  text-align: center;
  color: #76b729;
  font-weight: 500; }

@media screen and (min-width: 48em) {
  .Locations-wrapper {
    display: flex;
    justify-content: space-between; } }

.Locations-map {
  width: 100% !important;
  height: 100vh; }
  @media screen and (max-width: 47.9375em) {
    .Locations-map {
      width: calc(100% + 40px) !important;
      margin-left: calc(-20px);
      height: 500px;
      margin-bottom: 40px; } }
  @media screen and (min-width: 48em) and (max-width: 79.9375em) {
    .Locations-map {
      max-width: calc(50% + 40px);
      margin-left: calc(-40px); } }
  @media screen and (min-width: 80em) {
    .Locations-map {
      max-width: calc(50% + (100vw - 1200px) / 2);
      margin-left: calc(((100vw - 1200px) / 2) * -1); } }

@media screen and (min-width: 48em) and (max-width: 79.9375em) {
  .Locations-items,
  .Locations .NoDealers {
    width: calc(50% - 50px); } }

@media screen and (min-width: 80em) {
  .Locations-items,
  .Locations .NoDealers {
    width: calc(50% - 100px); } }

.Locations-item {
  position: relative;
  margin-bottom: 30px;
  background-color: #f2eeed;
  padding: 35px 20px; }
  @media screen and (min-width: 37.5em) {
    .Locations-item {
      padding: 35px 45px; } }
  .Locations-item:last-child {
    margin-bottom: 0; }

.Locations-title {
  color: #76b729;
  margin-bottom: 2px;
  font-size: 20px;
  font-weight: normal; }
  @media screen and (min-width: 64em) {
    .Locations-title {
      max-width: 300px; } }

.Locations-address {
  color: #2c2c2c;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "fieldwork", sans-serif; }

.Locations-links {
  display: inline-flex;
  flex-direction: column; }

.Locations-link {
  display: inline-block;
  margin-bottom: 3px;
  font-size: 14px;
  text-decoration: none;
  color: #2c2c2c; }
  .Locations-link--underline span {
    text-decoration: underline; }
  .Locations-link:hover {
    color: #76b729; }

.Locations-icon {
  height: 14px;
  width: 14px;
  margin-right: 15px; }

.Locations-info {
  display: block;
  margin-top: 20px; }
  .Locations-info p {
    font-size: 14px; }
    .Locations-info p:last-child {
      margin-bottom: 0; }

.Locations-head {
  flex-direction: column-reverse;
  display: flex; }
  @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
    .Locations-head {
      justify-content: space-between;
      flex-direction: row; } }
  @media screen and (min-width: 64em) {
    .Locations-head {
      display: flex;
      justify-content: space-between;
      flex-direction: row; } }

.Locations-labels {
  margin-bottom: 15px !important; }
  @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
    .Locations-labels {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 15px;
      margin-right: -50px; } }
  @media screen and (min-width: 64em) {
    .Locations-labels {
      margin-top: 0 !important;
      margin-left: 15px;
      margin-right: -50px; } }

.Locations-label {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #ffffff;
  padding: 6px;
  font-family: "robotoSlab", sans-serif;
  margin-bottom: 10px; }
  @media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
    .Locations-label {
      max-width: 180px; } }
  @media screen and (min-width: 64em) {
    .Locations-label {
      max-width: 180px; } }
  .Locations-label:last-child {
    margin-bottom: 0; }

.Filters {
  margin-bottom: 60px; }
  @media screen and (min-width: 37.5em) {
    .Filters {
      display: flex;
      align-items: center;
      flex-wrap: wrap; } }
  .Filters-label {
    margin-right: 5px;
    margin-bottom: 0px;
    font-size: 18px;
    color: #76b729;
    font-weight: 300;
    font-family: "robotoSlab", sans-serif; }
  .Filters-distance {
    margin: 0 20px; }
  .Filters-input {
    border: 1px solid #76b729;
    padding: 8px;
    height: 50px;
    width: 100%;
    margin: 15px 0;
    display: flex;
    align-items: center; }
    @media screen and (min-width: 37.5em) {
      .Filters-input {
        margin: 5px;
        max-width: 166px; } }
    .Filters-input--item {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid #76b729;
      width: 100%; }
      .Filters-input--item::placeholder, .Filters-input--item {
        margin-bottom: 0px;
        font-size: 13px;
        font-family: "fieldwork", sans-serif;
        color: #76b729; }
    .Filters-input, .Filters-input::placeholder {
      margin-bottom: 0px;
      font-size: 13px;
      font-family: "fieldwork", sans-serif;
      color: #76b729;
      display: flex;
      align-items: center; }
  .Filters-distanceOptions {
    transition: opacity .6s, visibility .6s;
    opacity: 0;
    visibility: hidden;
    border: 1px solid #76b729;
    left: -1px;
    width: calc(100% + 2px);
    top: 100%;
    position: absolute;
    z-index: 2;
    background-color: #ffffff; }
  .Filters-option {
    padding: 8px;
    transition: background-color .4s, color .4s;
    margin-bottom: 0px;
    font-size: 13px;
    font-family: "fieldwork", sans-serif;
    color: #76b729; }
    .Filters-option.is-active, .Filters-option:hover {
      cursor: pointer;
      background-color: #76b729;
      color: #ffffff; }
