/* socialMedia */
.Nav--socialMedia {

    .Nav {
        &-list {
            flex-wrap: nowrap;
        }

        &-icon,
        &-link {
            max-height: 18px;
        }

        &-link {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-item {
            margin-right: u($spacing-unit / 2);
        }
        &-icon {
            *{

                transition: fill .3s;
            }
        }
    }
}
