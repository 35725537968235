.Nav--privacy {

    .Nav-list {
        margin-left: u($spacing-unit--sm * -1);
    }

    .Nav-item,
    .Nav-link {
        font-family: $font-fieldwork;
        @include font-size(12, 0);
        color: $cc-black;
        opacity: .6;
    }

    .Nav-item {
        padding-left: u($spacing-unit--md);

        &:hover {
            .Nav-link {
                text-decoration: underline;
            }
        }
    }

    .Nav-link {
        opacity: 1 !important;
    }

}
