/* Button */

.Button {
    .Button-arrow {
        margin-left: 35px;

        * {
            transition: fill .3s;
        }
    }

    // Primary
    &--primary {
        border: none;
        color: $cc-red;
        font-family: $font-robotoSlab;
        padding: 10px 15px;

        .Button-arrow {
            * {
                fill: $cc-red;
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 5px;
            background-image: url('../img/icons/line.svg');
            background-repeat: no-repeat;
            background-size: 100% 5px;
        }

        &:hover {
            color: $cc-red--hover;

            .Button-arrow {
                * {
                    fill: $cc-red--hover;
                }
            }
        }

    }

    // Secondary
    &--secondary {
        color: $cc-green;
        border-color: $cc-green;
        background-color: transparent;
        transition: background-color .3s, color .3s;

        .Button-arrow {
            margin-left: 10px;
        }

        &--full {
            background-color: $cc-green;
            color: $white;

            &:hover {
                background-color: $cc-green--hover !important;
            }
        }

        &:hover {
            color: $white;
            background-color: $cc-green;

            .Button-arrow {
                * {
                    fill: $white;
                }
            }
        }

    }

    // Link
    &--link {
        color: $cc-green;
        @include font-size(13, 0);
        text-decoration: underline;

        &:hover {
            color: $cc-green;
            text-decoration: none;
        }
    }

    // Info
    &--info {
        background-color: $blue;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $blue--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $blue;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $blue--700;
            }
        }
    }

    // Success
    &--success {
        background-color: $green;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $green--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $green;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $green--700;
            }
        }
    }

    // Warning
    &--warning {
        background-color: $orange;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $orange--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $orange;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $orange--700;
            }
        }
    }

    // Danger
    &--danger {
        background-color: $red;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $red--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $red;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $red--700;
            }
        }
    }

    // Outline
    &--outline {
        background-color: transparent;
        border-color: currentColor;
    }

    // Large
    &--large {
        @include font-size($button-font-size * 1.25, false);
        padding-right: u($button-padding--r * 2);
        padding-left: u($button-padding--l * 2);
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($button-padding--r / 1.5);
        padding-left: u($button-padding--l / 1.5);
    }

    // Full
    &--full {
        display: block;
        width: 100%;
    }

    // Bare
    &--bare {
        border: 0;
        border-radius: 0;
        padding: 0;
    }
}
