/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-custom-font:                      "custom-font", sans-serif !default; // 400, 700
$font-cafFrancoise:                     "adorn-condensed-sans", sans-serif !default; // 400, 700
$font-robotoSlab:                       "robotoSlab", sans-serif !default; // 400, 700
$font-fieldwork:                        "fieldwork", sans-serif !default; // 400, 700




@font-face {
    font-family: "adorn-condensed-sans";
    src: url("https://use.typekit.net/af/1ec651/00000000000000003b9b0983/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/1ec651/00000000000000003b9b0983/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/1ec651/00000000000000003b9b0983/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}



//RobotoSlab
@font-face {
    font-family: 'robotoSlab';
    src: url('#{$path-fonts}robotoSlab/robotoslab-regular-webfont.woff2') format('woff2'),
    url('#{$path-fonts}robotoSlab/robotoslab-regular-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'robotoSlab';
    src: url('#{$path-fonts}robotoSlab/robotoslab-bold-webfont.woff2') format('woff2'),
    url('#{$path-fonts}robotoSlab/robotoslab-bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

//Fieldwork
@font-face {
    font-family: "fieldwork";
    src: url("https://use.typekit.net/af/67fa9b/00000000000000003b9b3432/27/l?subset_id=2&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/67fa9b/00000000000000003b9b3432/27/d?subset_id=2&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/67fa9b/00000000000000003b9b3432/27/a?subset_id=2&fvd=n3&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "fieldwork";
    src: url("https://use.typekit.net/af/868bcb/00000000000000003b9b3436/27/l?subset_id=2&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/868bcb/00000000000000003b9b3436/27/d?subset_id=2&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/868bcb/00000000000000003b9b3436/27/a?subset_id=2&fvd=n6&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "fieldwork";
    src: url("https://use.typekit.net/af/0f0120/00000000000000003b9b3437/27/l?subset_id=2&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/0f0120/00000000000000003b9b3437/27/d?subset_id=2&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/0f0120/00000000000000003b9b3437/27/a?subset_id=2&fvd=i6&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 600;
}