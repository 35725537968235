/* Logo */
.Logo {
    flex-shrink: 0;

    svg {
        display: block;
        width: u(115px);

        .Header & {
            @include mq ($until: $viewport--md - 1){
                width: u(65px);
                height: u(55px);

            }
            @include mq ($from: $viewport--md){
                position: relative;
                left: 10px;
            }
        }
    }
}
