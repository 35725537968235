/* Colors: Custom */

// Grey
$cc-grey:                           #f2eeed !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);
$cc-grey--light:                           #F3EFED !default;
$cc-grey--dropdown:                           #f7f7f7 !default;


$cc-green:                          #76b729;
$cc-green--hover:                    scale-color($cc-green, $lightness: +10%);
$cc-black:                          #2c2c2c;
$cc-red:                          #a83345;
$cc-red--hover:                          scale-color($cc-red, $lightness: +30%);