/* Header */
.show-dropdown{
    .Header{
        opacity: 1 !important;
        // transform: translateY(0) !important;
    }
}

.Header {
    background-color: $header-bg-color;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
    // height: u($structure-header-height);
    opacity: 1;
    @include mq($until: $viewport--md - 1) {
        .show-nav & {
            height: 75px;
        }
    }



    &--sticky {
        @include transition(#{background-color, color, height, transform, opacity});
    }

    &.is-unpinned {
        transform: translateY(-100%);
        opacity: 0;
    }

    &.is-notTop {
        background-color: $white;
        // box-shadow: 0 0 2px 2px rgba($cc-black, .05);
    }


    &-top {
        background-color: $cc-green;
        padding-top: u($spacing-unit / 2);
        padding-bottom: u($spacing-unit / 2);
        transition: height .3s, padding .3s;
        z-index: 3;
        position: relative;

        .Nav--socialMedia {
            .Nav-link {
                &:hover {
                    .Nav-icon {
                        * {
                            fill: $cc-grey;
                        }
                    }
                }
            }
        }

        @include mq($until: $viewport--md - 1) {
            .show-nav & {
                height: 0;
                padding: 0;
                z-index: -1;
            }
        }
    }

    &-main {
        background-color: $header-bg-color;
        padding-top: u($spacing-unit / 2);
        padding-bottom: u($spacing-unit / 2);
        transition: background-color .3s;


        @include mq($until: $viewport--md - 1) {
            .show-nav & {
                background-color: $cc-grey;
            }
        }
    }

    >.Container {
        display: flex;
        align-items: center;
    }
}
