.Nav--footer {
    max-width: 600px;
    width: 100%;
    margin: 0 20px;

    @include mq ($until: 459) {
        margin: 20px 0;
    }

    .Nav-list {
        margin-left: u($spacing-unit--sm * -1);
        display: block;
        columns: 2;

        @include mq ($from: $viewport--sm) {
            columns: 3;
        }

    }

    .Nav-item {
        padding-left: u($spacing-unit--sm);

        &:hover {
            .Nav-link {
                color: $cc-green;
            }
        }
    }

    .Nav-link {
        color: $cc-black;
        @include font-size(13, 0);
        line-height: 30px;
        font-family: $font-robotoSlab;
        transition: color .3s;
    }
}
