/* Main */
.Nav--main {
    width: 100%;

    @include mq($until: $viewport--md - 1) {

        display: none;
    }

    &--mobile {
        display: none;
        @include mq($until: $viewport--md - 1) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
        }
    }

    // List
    .Nav-list {
        // margin-left: u($spacing-unit * -1);
        align-items: center;

        @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {
            margin-left: u($spacing-unit / 1.5 * -1);
        }

        @include mq ($from: $viewport--lg) {
            margin-left: u($spacing-unit * 2 * -1);
        }

        @include mq($until: $viewport--md - 1) {
            flex-direction: column;
            align-items: center;
            justify-content: center;

        }
    }

    // Item
    .Nav-item {
        &:first-child {
            // padding-left: 0;
        }

        &.Logo {
            // padding-left: 55px;
            position: relative;
            z-index: 3;
            height: 81px;

            &::before,
            &::after {
                @include mq($until: $viewport--md - 1) {
                    display: none;
                }

                content: '';
                position: absolute;
                border-radius: 50%;
            }

            &::before {
                width: 255px;
                height: 255px;
                bottom: -50px;
                background-color: transparent;
                border: 4px solid $white;

                @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {
                    left: -55px;
                }

                @include mq ($from: $viewport--lg) {
                    left: -28px
                }
            }

            &::after {
                width: 235px;
                height: 235px;
                bottom: -40px;
                background-color: $white;
                z-index: -1;

                @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {
                    left: -45px;
                }

                @include mq ($from: $viewport--lg) {
                    left: -18px
                }
            }
        }



        @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {
            padding-left: u($spacing-unit / 1.5);
        }

        @include mq ($from: $viewport--lg) {
            padding-left: u($spacing-unit * 2);
        }

        font-family: $font-robotoSlab;
        text-transform: lowercase;
        @include font-size(17, 0);
        letter-spacing: .94px;
        z-index: 3;

        @include mq($until: $viewport--md - 1) {
            color: $cc-green;

            +.Nav-item {
                margin-top: u($spacing-unit--sm);
            }

            .show-nav & {
                @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                @for $i from 1 through 10 {
                    $delay: (0.02s * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }
            }

            html:not(.show-nav) & {
                opacity: 0;
                transform: translateY(#{u($spacing-unit)});
            }
        }
    }

    // Link
    .Nav-link {
        .Icon-down {
            @include mq($until: $viewport--md - 1) {
                display: none;
            }

            @include dimensions(10, 10);

            * {
                transition: fill .4s;
                fill: $black;
            }
        }

        &.is-active,
        &:hover {
            color: $cc-green !important;

            .Icon-down {
                * {
                    fill: $cc-green;
                }
            }
        }

        @include mq($until: $viewport--md - 1) {

            &.is-active,
            &:hover {
                font-weight: bold;
            }
        }

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }
    }
}
