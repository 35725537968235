/* General */

.scrollmagic-pin-spacer{
    padding-bottom: 0 !important;
}







.Test {
    border: 1px solid red;
}

.gutter-sizer {
    width: 0 !important;
}



.Parent-hidden {
    opacity: 0 !important;
    height: 0 !important;

}

.Parent {
    height: auto;
    opacity: 1;
    transition: opacity .4s;

    &-title {
        @include font-size(25);
        margin-bottom: u($spacing-unit * 1.5) !important;
        font-weight: 500;
        color: $cc-green;
        display: inline-block;
        padding-bottom: 5px;
        border-bottom: 2px solid $cc-green;

    }
}

// Descandants
.Descandants {
    @include font-size(12, 0);
    position: relative;
    left: 3px;

    .Dropdown-link & {
        left: -3px;
        font-size: 10px;
        bottom: 5px;
    }
}

// Return
// .Return {

//     &,
//     &:hover {
//         color: $cc-green;
//     }

// }


.Content {
    .CalendarPage & {
        padding-bottom: 0;
    }
}

.Form-field,
input {
    border-radius: 0;

    &:hover,
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.Month-item,
.Months-item {
    font-family: $font-robotoSlab !important;
}

.Icon-down {
    overflow: hidden;
}

// show-dropdow
html {
    body {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            background-color: #3f3e3e;
            z-index: -99999;
            transition: opacity .4s, visibility .4s;
            visibility: hidden;
            z-index: 3;
        }
    }
}

.show-dropdown {
    body {
        &::before {
            visibility: visible;
            opacity: .75;
        }
    }
}

// Months
.Months {
    .CalendarPage & {

        margin-top: u($spacing-unit * 4);
        margin-bottom: u($spacing-unit);

    }

    &-title {
        color: $cc-green;
        font-family: $font-cafFrancoise !important;
        @include font-size(35, 22);
        line-height: 39px;
        font-weight: normal;
        max-width: 258px;
        font-weight: 600;
    }

    &-items {
        margin: 1px 0 0 1px;
        display: flex;
        flex-wrap: wrap;
        // border: 1px solid $cc-green;
    }

    &-item {
        margin: -1px 0 0 -1px;
        padding: 12px 25px;
        font-size: 15px;
        color: $cc-green;
        border: 1px solid $cc-green;

        .CalendarPage & {
            width: 75px;
        }

        position: relative;
        text-decoration: none;

        &::before {
            content: '';
            position: absolute;
            left: -4px;
            top: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            background-color: $cc-green;
            transition: opacity .4s;
            z-index: -1;
            opacity: 0;
        }

        .CalendarPage & {
            &:hover {
                color: $white;

                &::before {
                    opacity: 1;
                }
            }
        }

        &.no-hover {
            pointer-events: none;
        }

        &.is-active {
            background-color: $cc-green;
            color: $white !important;

            .Months-item {
                color: $white;

            }

            cursor: pointer;

            &::before {
                opacity: 1;
            }

        }
    }
}

// Form
.Form {
    width: 100%;

    @include mq ($until: $viewport--sm - 1) {
        margin-top: u($spacing-unit * 3);
    }

    @include mq ($from: $viewport--sm) {
        max-width: 490px;
        margin-left: u($spacing-unit);
    }

    &-item {
        &--input {
            margin-bottom: u($spacing-unit * 3.75) !important;
        }
    }

    &-field {
        max-width: 100%;
        padding-bottom: 15px;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid $cc-black;

        &--checkbox {
            border: none;
        }
    }
}

// DefaultPagePage
.DefaultPagePage {
    overflow-x: hidden;
}

// DownloadsPagePage
.DownloadsPagePage {
    background-color: $cc-grey;

    .Intro {
        text-align: center;
        max-width: 500px;
        margin-top: u($spacing-unit * 4) !important;
        margin: 0 auto;

        .Title-line {
            font-family: $font-robotoSlab !important;
            text-transform: initial !important;
        }
    }
}

// ContactPage
.ContactPage {
    .Contact {
        @include mq ($from: $viewport--sm) {
            display: flex;
            justify-content: space-between;
        }

        .Title-line {
            font-family: $font-robotoSlab !important;
            text-transform: initial;
        }

        &-item {
            color: $cc-green;
            text-decoration: none;
            display: inline-block;
            margin-bottom: 10px;

            &:hover {
                text-decoration: underline;
            }
        }

        &-icon {
            margin-right: 30px;
        }

        &-nav {
            margin-top: u($spacing-unit);

            p {
                margin-bottom: 0;
                font-size: 16px;
            }

            .Nav--socialMedia {
                margin-left: u($spacing-unit / 1.5);

                .Nav-icon {
                    * {
                        fill: $cc-green;
                    }
                }
            }
        }
    }
}

// Downloads
.Downloads {
    max-width: 994px;
    margin: u($spacing-unit * 2, auto);

    &-item {
        background-color: $white;
        width: 100%;
        margin-bottom: u($spacing-unit);
        padding-bottom: u($spacing-unit);
        padding: 8px;

        @include mq ($from: $viewport--sm) {
            padding: 8px;
            margin-bottom: u($spacing-unit * 2.5);
            display: flex;
            min-height: 240px;
        }
    }

    &-media {
        width: 100%;
        position: relative;

        @include mq ($until: $viewport--sm - 1) {
            margin-bottom: u($spacing-unit);
        }

        @include mq ($from: $viewport--sm) {
            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        @include mq ($from: $viewport--sm, $until: $viewport--lg - 1) {
            max-width: 280px;
            min-width: 280px;
        }

        @include mq ($from: $viewport--lg) {
            max-width: 360px;
            min-width: 360px;
        }

        @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
            margin-right: u($spacing-unit);
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            margin-right: u($spacing-unit * 2.5);
        }

        @include mq($from: $viewport--lg) {
            margin-right: u($spacing-unit * 5);
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h2 {
            @include font-size(20, 10);
            color: $cc-green;
            font-weight: normal;
        }

    }
}

//Share balk
.Share {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-text {
        @include font-size(16, 0);

        margin: 0 5px;
    }

    &-link {
        margin: 0 5px;
    }

    &-icon {
        @include dimensions(20, 20);

        * {
            fill: $cc-green;
        }
    }
}

// Pagination
.Pagination {
    text-align: center;

    &-item {
        margin: 0 5px;
        border: 1px solid $cc-green;
        color: $cc-green;
        text-decoration: none;
        padding: 9px 12px;
        font-size: 13px;
        transition: background-color .4s, color .4s;
        display: inline-block;
        margin-bottom: 25px;

        &.is-active,
        &:hover {
            background-color: $cc-green;
            color: $white;
        }

        &--medium {
            padding: 9px 15px;
        }

        &--large {
            padding: 9px 21px;
        }
    }
}

// Months
.Month {
    margin-bottom: u($spacing-unit);

    &:first-of-type {
        margin-top: u($spacing-unit * 2);
    }

    h1 {
        font-size: 28px !important;

        &::before {
            left: 0;
        }
    }

    &-wrapper {
        border: 1px solid $cc-green;
        display: inline-flex;
        flex-wrap: wrap;
    }

    &-item {
        @include font-size(12, 0);
        padding: 11.5px;
        color: $cc-green;
        line-height: normal !important;

        &.is-active {
            position: relative;
            z-index: 2;
            color: $white;

            &::before {
                content: '';
                position: absolute;
                left: -1px;
                top: -7px;
                height: calc(100% + 14px);
                width: 100%;
                background-color: $cc-green;
                z-index: -1;
            }
        }
    }
}

// Custom Text (gekleurde opmaak)
.CustomText {
    em {
        font-style: normal;
        color: $cc-green;
    }
}

// Custom Titles
.Title {
    &-line {
        color: $cc-green;
        font-family: $font-cafFrancoise !important;
        @include fluid-type(font-size, $viewport--xs, $viewport--md, 28, 35);
        text-transform: uppercase;
        font-weight: 500;
        position: relative;
        padding-bottom: u($spacing-unit / 1.5);

        &--big {
            font-weight: 600 !important;
        }

        &--left {
            &::before {
                left: 0 !important;
            }

        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: calc(50% - 30px);
            width: 60px;
            height: 2px;
            background-color: $cc-green;
        }
    }
}

// Homepage blocks
.HomepageBlock {
    margin-bottom: u($spacing-unit * 6);
}

.AssortimentOverview {
    background-color: $cc-grey;
    padding-top: u($spacing-unit * 4) !important;
    padding-bottom: u($spacing-unit * 2) !important;

    &-icon {
        @include dimensions(230, 200);
        position: absolute;
        right: 0px;
        z-index: 1;

        @include mq ($from: $viewport--xs, $until: $viewport--md - 1) {
            display: none;
        }

        @include mq ($from: $viewport--xl + 80px) {
            right: -20px;
        }
    }

    &-mediaWrapper {
        text-decoration: none;
        background-color: red;
        position: relative;
        background: url('#{$path-img}/polaroid.png') no-repeat;
        background-size: cover;
        // background-color: #fbfbf7;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
        padding: u($spacing-unit);
        margin-bottom: u($spacing-unit);

        @include mq ($from: $viewport--md) {
            margin-bottom: u($spacing-unit * 2);
        }

        margin-top: u($spacing-unit * 2);
    }

    &-title {
        margin-top: u($spacing-unit);
        @include font-size(25, 0);
        color: $cc-red;
        text-align: center;
        font-weight: normal;
    }

    &-items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 1046px;
        margin-left: auto;
        margin-right: auto;
    }

    &-item {
        width: 100%;
        margin-bottom: u($spacing-unit * 2);

        p {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

        @include mq ($from: $viewport--sm, $until: $viewport--md - 1) {
            max-width: calc((100% / 2) - 15px);
        }

        @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {
            max-width: calc((100% / 3) - 15px);
        }

        @include mq ($from: $viewport--lg) {
            max-width: calc((100% / 3) - 35px);
        }
    }
}

.FindLocation {
    overflow: hidden;
    background-color: $cc-red;
    color: $white;
    padding-top: u($spacing-unit * 5) !important;
    padding-bottom: u($spacing-unit * 5) !important;
    z-index: 2;

    &-icon {
        @include mq($until: $viewport--sm) {
            display: none;
        }

        position: absolute;
        z-index: -1;

        &--1 {
            top: 0;
            left: 100px;
        }

        &--2 {
            transform: scale(-1, 1);
            bottom: -10px;
            left: -50px;
        }

        &--3 {
            bottom: 0;
            right: 50px;
        }
    }

    .Title-line {
        color: $white;
        font-family: $font-robotoSlab !important;
        font-size: 28px;
        text-transform: inherit;

        &::before {
            background-color: $white;
        }
    }

    &-wrapper {
        max-width: 405px;
        margin: 0 auto;
    }

    &-form {
        margin: 0 auto;
        max-width: 320px;
        width: 100%;
        display: flex;

        &--input {
            width: 100%;
            background-color: transparent;
            border: 1px solid $white;
            box-shadow: none;
            padding: 14px;

            &,
            &::placeholder {
                color: $white;
                font-family: $font-fieldwork;
                font-size: 14px;
                text-align: center;
            }
        }

        &--button {
            background-color: $white;
            width: 45px;
            min-width: 46px;
        }

        &--icon {}
    }
}

// LazySizes
.LazyLoad,
.LazyLoading {
    opacity: 0;
}

.LazyLoaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}
