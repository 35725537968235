/* Lang */
.Nav--lang {
    @include position(relative, $z: 1);

    @include mq($until: $viewport--md - 1) {
        .show-nav & {
            @include position(relative, $z: -1);
        }
    }

    &:not(:hover) {
        .Nav-list {
            opacity: 0;
            visibility: hidden;
        }
    }

    // Toggle
    .Nav-toggle {
        cursor: default;
        align-items: center;
        display: flex;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: .67px;
        color: $white;
        font-family: $font-robotoSlab;

        svg {
            @include dimensions(7, 7);

            * {
                fill: $white;
            }
        }
    }

    // List
    .Nav-list {
        @include transition(#{opacity, visibility});
        @include position(absolute, $top: 100%, $right: -2px);
        color: $white;
        font-family: $font-robotoSlab;
        background-color: $cc-green;
        flex-direction: column;
    }

    // Link
    .Nav-link {
        color: currentColor;
        padding: u($spacing-unit--xs, $spacing-unit--xs * 2);
        text-transform: uppercase;
        font-size: 12px;

        &:not(.is-active):hover {
            background-color: $cc-green--hover
        }
    }
}
