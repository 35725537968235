.RecipeOverview {
    overflow: hidden;
}

.RecipesPage {
    background-color: $cc-grey;
}

.RecipeGrid {
    max-width: 1100px;
    margin: 0 auto;
    position: relative;

    &:last-child{
        margin-bottom: u($spacing-unit * 5);
    }

    &-icon {
        position: absolute;
        right: 0;
        top: -150px;
        right: -150px;
    }

    &:nth-of-type(even) {
        direction: rtl
    }

    @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
        @include grid(1fr 1fr, 1fr 1fr 1fr);
    }

    @include mq($from: $viewport--lg) {
        @include grid(1fr 1fr 1fr, 1fr 1fr);

    }

    &-item {
        text-decoration: none;
        position: relative;
        min-height: 257px;
        margin: 7px;
        display: flex;
        align-items: flex-end;
        border: 1px solid transparent;
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        &:nth-of-type(1) {
            @include mq($from: $viewport--sm) {
                @include grid-area(1, 2, 1, 3);
            }
        }

        &:nth-of-type(2) {
            @include mq($from: $viewport--sm) {
                @include grid-area(2, 3, 1, 2);
            }
        }

        &:nth-of-type(3) {
            @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                @include grid-area(2, 3, 2, 3);
            }

            @include mq($from: $viewport--lg) {
                @include grid-area(3, 4, 1, 2);
            }
        }

        &:nth-of-type(4) {
            @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                @include grid-area(1, 2, 3, 4);
            }

            @include mq($from: $viewport--lg) {
                @include grid-area(2, 3, 2, 3);
            }
        }

        &:nth-of-type(5) {
            @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
                @include grid-area(2, 3, 3, 4);
            }

            @include mq($from: $viewport--lg) {
                @include grid-area(3, 4, 2, 3);
            }
        }

    }

    &-bg {
        display: block;

        &,
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        &::before {
            content: '';
            background-image: linear-gradient(to bottom, rgba(216, 216, 216, 0), rgba(44, 44, 44, 0.75));
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform .4s;
            z-index: -1;
        }

    }

    &-content {
        direction: ltr;
        z-index: 2;
        position: relative;
        color: $white;
        margin-top: auto;
        padding: 25px;
        margin-right: auto;
    }

    &-title {
        @include font-size(25, 10);
        font-weight: normal;
    }

    &-cookItem {
        @include font-size(13, 0);
        font-family: $font-fieldwork;
        display: flex;

        &--icon {
            @include dimensions(15, 15);
            margin-right: 5px;
        }
    }
}

.Recipe {

    &-wrapper {
        padding: u($spacing-unit * 5, 0);

        &:first-of-type {
            padding-top: 0;
        }

        &:nth-child(odd) {
            background-color: $white;

            .Recipe {
                flex-direction: row-reverse;

                &-media {
                    border-color: $cc-grey;
                }
            }
        }
    }

    .Intro {
        margin-bottom: 30px;

        .RecipeGrid-cookTime {
            margin-right: 30px;
        }

        .RecipeGrid-cookItem {
            margin-bottom: 15px;

            &--icon {
                * {
                    stroke: $cc-black;
                }
            }
        }
    }

    &-title {
        font-family: $font-robotoSlab !important;
        font-size: 25px;
        text-transform: initial;
        color: $cc-green !important;
    }

    ul {

        // padding-left: u($spacing-unit * 1.5);
        li {
            line-height: 29px;
            position: relative;
            list-style: none;

            &::before {
                content: "";
                position: absolute;
                left: calc((#{u($spacing-unit)} + 3px) * -1);
                top: calc(50% - 3px);
                @include dimensions(6, 6);
                border-radius: 50%;
                background-color: $cc-green;
            }
        }
    }
}

.Related {
    h1 {
        text-align: center;
    }

    .Title-line{
        font-family: $font-robotoSlab !important;
        text-transform: initial;
        font-size: 30px;
    }

    .Assortiment-items {
        justify-content: center;
    }
}
