.Banner {
    background-color: $cc-grey;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        height: 75px;
        background-image: url('../img/icons/scratch.svg');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 3;
    }

    &--large .Banner-media,
    &--large .Banner-media img {
        @include mq ($from: $viewport--md) {
            max-height: 470px !important;
        }
    }

    &--small .Banner-media,
    &--small .Banner-media img {
        @include mq ($from: $viewport--md) {
            max-height: 320px !important;
        }
    }

    @include mq ($from: $viewport--md) {
        // max-height: 320px;
        @include grid(55% 1fr, 100%);
    }

    &-media {
        @include mq ($from: $viewport--md) {
            @include grid-area(1, 2, 1, 2);

            position: relative;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 50%;
                background-image: linear-gradient(to right, rgba($cc-grey--light, 0), $cc-grey 80%);
                z-index: 2;
            }

        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &-content {
        @include fluid-type(margin-left, $viewport--md, $viewport--xl, 0, 50);

        @include mq ($until: $viewport--md - 1) {
            padding: u($spacing-unit * 3, $spacing-unit, $spacing-unit * 4);
        }

        @include mq ($from: $viewport--md) {
            @include grid-area(2, 3, 1, 2);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: u($spacing-unit * 2, 0);
        }


        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
            max-width: calc((45 / 100) * #{$structure-width}px);
        }

        @include mq($from: $viewport--lg) {
            &, p{
                max-width: 440px;
            }
        }
    }

    &-title {
        @include fluid-type(font-size, $viewport--xs, $viewport--md, 28, 35);
        font-family: $font-robotoSlab;
        color: $cc-green;
        font-weight: normal;
        position: relative;
        padding-bottom: u($spacing-unit);
        @include mq($from: $viewport--lg) {
            max-width: 440px;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60px;
            height: 2px;
            background-color: $cc-green;
        }

        &--large {
            @include fluid-type(font-size, $viewport--xs, $viewport--md, 28, 45);
            margin-bottom: 0;
            padding-bottom: 10px;

            &::before {
                display: none;
            }
        }
    }

    .FindLocation-form {
        margin-right: auto;
        margin-left: 0;

        &--input {
            border-color: $cc-red;

            &,
            &::placeholder {
                color: $cc-red;
            }
        }

        &--button {
            background-color: $cc-red;
        }

        &--icon {
            * {
                stroke: $white;
            }
        }
    }

    .Slider-pagination {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        top: 0;
        left: 0;

        @include mq ($from: $viewport--xl) {
            left: 10px;
            // left: calc(((100vw - #{$structure-width}px) / 2) - 40px);
        }



        &-item {
            margin: 9px !important;
            opacity: 1;
            background: transparent;
            border: 1px solid $white;
            transition: background .3s;

            &.is-active,
            &:hover {
                background-color: $white;
            }
        }
    }
}
