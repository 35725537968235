/* Slider: navigation */
.Slider-button {
    @include dimensions(27, 44);
    @include position(absolute, $top: 50%, $z: 10);
    margin-top: u(-22px);
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 27px 44px;

    &.is-disabled {
        cursor: auto;
        opacity: 0.35;
        pointer-events: none;
    }

    &.is-locked {
        display: none;
    }
}

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAjCAMAAABB9MFTAAAAG1BMVEUAAAD////////////////////////////////rTT7CAAAACHRSTlMAQFBgkJ+gv9iGVP8AAAAxSURBVHgBY0AHzEwYQmwcHFiEmEeFQIAFU4iBg4MdM1w5OFiJEBwVZMEmyIABGBkZAF/NAyTxd+ksAAAAAElFTkSuQmCC);
    right: auto;
    left: 10px;
}

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAjCAMAAABB9MFTAAAAJFBMVEUAAAD///////////////////////////////////////////+0CY3pAAAAC3RSTlMAEDBAcI+QoM/Q7wWHhpAAAAAxSURBVCjPY2BiZWRAB2zcHBiCjJyjgmQKMnNzs6AJMXFxs48K4RACpj8MIQZGFgwhAFdVA+FJbpRNAAAAAElFTkSuQmCC);
    right: 10px;
    left: auto;
}


.Slider-navigation {
    position: absolute;
    top: calc(50% - 16px);
    left: 3%;
    width: 94%;
    height: 32px;
    z-index: 2;
    display: flex;
    justify-content: space-between;

    .Slider-button--next,
    .Slider-button--prev {
        @include dimensions(18, 32);
        background-repeat: no-repeat;

        &:hover {
            cursor: pointer;
        }
    }
}
