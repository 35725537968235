/* Mixin: Truncate */
@mixin truncate($lines: 1, $substract: 0) {
    @if $lines == 1 {
        max-width: 100% - $substract;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    } @else {
        box-orient: vertical;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        display: box;
        line-clamp: $lines;
        -webkit-line-clamp: $lines;
        overflow: hidden;
    }
}
