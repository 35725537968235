.OverviewPagePage,
.AssortimentOverviewPagePage {
    background-color: $cc-grey;
}

.AssortimentOverviewPagePage {
    .Months-item {
        width: auto !important;
    }
}

.Overview-assortiment {
    margin: 0 auto;
    max-width: 1080px;

    .Intro {
        margin: u($spacing-unit * 2, 0);

        @include mq($from: $viewport--md) {
            margin: u($spacing-unit * 4, 0);
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        h1 {
            color: $cc-green;
            font-size: 35px;
            max-width: 410px;
            width: 100%;
            font-weight: normal;
        }

        .CustomText {
            width: 100%;
            max-width: 457px;
        }
    }

}

.Assortiment {
    &-fullWidth {
        visibility: hidden;
        opacity: 0;
        display: none;
        justify-content: space-between;
        height: auto;

        .Months {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            min-width: 145px;

            @include mq($until: $viewport--md - 1) {
                margin-bottom: u($spacing-unit);
            }

            @include mq($until: $viewport--lg - 1) {
                margin-right: 10px;
            }

            &-items {
                display: flex;
                align-items: center;

                &:first-child {
                    margin-bottom: u($spacing-unit);
                }

                h3 {
                    margin: 0;
                    padding: 0;
                    font-family: $font-cafFrancoise;
                    font-weight: normal;
                    font-size: 20px;
                    margin-right: 15px;
                    font-weight: 600;

                    @include mq($from: $viewport--lg) {
                        margin-right: 30px;
                    }
                }

            }

            &-item {
                @include font-size(12, 0);
                border: 1px solid $cc-green;
                padding: 12px 11px;
                color: $cc-green;
                font-family: $font-robotoSlab !important;

                @include mq($until: $viewport--lg) {
                    display: none;
                }

                &.is-active {
                    display: block;
                    background-color: $cc-green;
                    color: $white;
                    position: relative;
                    z-index: 2;

                    &::before {
                        content: '';
                        position: absolute;
                        left: -2px;
                        top: -5px;
                        height: calc(100% + 10px);
                        width: calc(100% + 4px);
                        background-color: $cc-green;
                        z-index: -1;
                    }
                }
            }
        }

        .Info {
            width: 100%;
            max-width: 270px;
            color: $cc-black;

            h4 {
                @include font-size(18, 0);
                font-weight: normal;
            }

            p {
                @include font-size(16, 10);
            }

            .Button {
                margin-bottom: 0;
            }
        }
    }

    &-Wrapper {
        max-width: 1080px;
        margin: 0 auto;
    }

    &-items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &.Overlay {
            overflow: hidden;



            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $cc-grey;
                z-index: 3;
                background-image: url(http://www.broadwaybalancesamerica.com/images/ajax-loader.gif);
                background-repeat: no-repeat;
                background-position: top center;
            }
        }
    }

    &-item {
        // transition: width .6s;
        margin-bottom: u($spacing-unit * 2);

        @include mq ($from: $viewport--md) {
            margin-bottom: u($spacing-unit * 2.5);
        }

        text-decoration: none;
        flex-direction: column;

        &--filter {
            display: block;
            width: 100%;

            @include mq ($from: $viewport--sm, $until: $viewport--md - 1) {
                width: calc((100% - 30px) / 2);
            }

            @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {
                width: calc((100% - (30px * 2)) / 3);
            }

            @include mq ($from: $viewport--lg) {
                width: calc((100% - (30px * 3)) / 4);
            }
        }

        h3 {
            color: $cc-green;
            font-family: $font-robotoSlab;
            font-weight: normal;
            @include font-size(23, 0);
            margin-top: auto;
            padding-top: u($spacing-unit);
        }


        @include mq($from: $viewport--sm) {
            &.FullWidth {
                display: flex;
                flex-direction: row;
                width: 100% !important;


                .Assortiment-content {
                    width: 100%;
                    background-color: $white;
                    padding: 20px 10px;

                    @include mq($from: $viewport--lg) {
                        padding: 20px 40px;
                    }
                }

                .Assortiment-fullWidth {
                    visibility: visible;
                    opacity: 1;
                    display: block;

                    @include mq($from: $viewport--md) {
                        display: flex;
                    }

                    margin-top: u($spacing-unit * 2);
                }

                .Assortiment-media {
                    max-width: 275px;
                    width: 100%;

                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }

    &-media {
        display: block;
        border: 13px solid $white;
    }

    &-search {
        @include mq ($from: $viewport--sm) {
            display: flex;
            justify-content: space-between;
        }

        margin-bottom: u($spacing-unit * 2.5);

        .Layout {
            @include mq ($until: $viewport--sm - 1) {
                display: none;
            }

            display: flex;
            max-width: 65px;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            position: relative;

            &::before {
                position: absolute;
                content: '';
                height: 17px;
                width: 1px;
                background-color: $cc-green;
                left: calc((65px / 2) - (1px / 2));
                top: 0;

                @include mq ($from: $viewport--sm) {
                    top: calc((50px - 17px) / 2);
                }
            }

            &-icon,
            &-item {
                @include dimensions(17, 17);
                display: block;
            }
        }
    }

    &-filters {
        @include mq($from: $viewport--sm) {
            display: flex;
            flex-grow: 1;
            align-items: center;
        }

        @include mq ($until: $viewport--sm - 1) {
            margin-bottom: u($spacing-unit);
        }

        &--title {
            @include font-size(18, 0);
            color: $cc-green;
            margin-right: 10px;
            min-width: 100px;
        }

        .Kenmerken {
            min-width: 166px;
            position: relative;

            @include mq($until: $viewport--sm - 1) {
                margin: u($spacing-unit, 0);
            }

            &.is-active {
                .Dropdown-togle {
                    .Icon-down {
                        transform: rotate(180deg);
                    }
                }

                .Dropdown {
                    z-index: 2;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .Dropdown-togle {
            height: 50px;
            width: 100%;
            padding: 15px 20px;
            font-size: 13px;
            border: 1px solid $cc-green;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $cc-green;
            text-decoration: none;

            .Icon-down {
                @include dimensions(10, 10);
                transition: transform .3s;

                * {
                    fill: $cc-green;
                }
            }
        }

        .Dropdown {
            position: absolute;
            background-color: $cc-grey--dropdown;
            width: 100%;
            border: 1px solid $cc-green;
            top: calc(100% + 10px);
            padding: 20px;
            max-height: 182px;
            overflow-y: scroll;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                box-shadow: inset 0 0 10px 10px $cc-green;
                border: solid 3px transparent;
            }

            &-item {
                text-decoration: none;
                display: flex;
                @include font-size(13, 15);
                color: $cc-green;
                align-items: center;

                .Count {
                    color: $cc-black;
                    font-size: 10px;
                    margin-left: 5px;
                    font-weight: 300 !important;
                }

                &:hover,
                &.is-active {
                    font-weight: bold;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-input {
        @include mq ($until: $viewport--sm - 1) {
            width: 100%;
        }

        @include mq ($from: $viewport--sm) {
            margin-left: 10px;
            max-width: 166px;
        }

        min-width: 166px;
        height: 50px;
        border: 1px solid $cc-green;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;

        &--item {
            width: 100%;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $cc-green;

            &,
            &::placeholder {
                color: $cc-green;
                font-size: 13px;
            }
        }

        &-icon {}
    }
}

.Related {
    padding: u($spacing-unit * 4, 0);
    background-color: $cc-grey;
}

.AssortimentBanner,
.Recipe {
    h1 {
        color: $cc-green;
        font-weight: normal;
        @include font-size(35, 20);
    }

    h2 {
        color: $cc-black;
        font-weight: normal;
        @include font-size(25, 20);
    }

    p {
        line-height: 26px;
        ;
    }

    @include mq ($from: $viewport--md) {
        display: flex;
        justify-content: space-between;
    }

    &-wrapper {
        background-color: $cc-grey;
    }

    &-media {
        @include mq ($until: $viewport--md - 1) {
            margin-bottom: u($spacing-unit * 2);
        }

        @include mq ($from: $viewport--md) {
            max-width: 480px;
            margin-left: auto;
        }

        width: 100%;
        border: 8px solid $white;
        height: 100%;

        .Download {
            position: absolute;
            bottom: -1px;
            right: 0;
            z-index: 2;
            padding: 12px 10px 12px 15px;
            background-color: $white;

            &:hover {
                .Download-link {
                    text-decoration: underline;
                }
            }

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 45px;
                background-color: $cc-green;
                z-index: -1;
            }

            &-link {
                color: $cc-green;
                text-decoration: none;
                display: flex;
                align-items: center;
            }

            &-icon {
                width: 25px;
                margin-left: 20px;
            }

        }
    }

    &-content {
        max-width: 550px;
        width: 100%;

        @include mq ($from: $viewport--md) {
            margin-left: u($spacing-unit * 4);
        }
    }

    .Label {
        font-family: $font-robotoSlab;
        position: absolute;
        top: 75px;
        left: 115px;
        @include mq ($until: $viewport--xl - 1){
            @include fluid-type(left, $viewport--lg, $viewport--xl, 10, 40);
        }
        z-index: 2;
        padding: 12px 10px 12px 15px;
        background-color: $cc-green;
        color: $white;
        @include font-size(12, 0);
    }
}
