.Nav--main {
    @include position(relative, $z: 1);

    .Nav-item {
        &:not(:hover) {
            .Dropdown {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .Dropdown {
        margin: 0;
        padding: u($spacing-unit * 2, $spacing-unit * 4);
        @include transition(#{opacity, visibility});
        position: absolute;
        left: -40px;
        top: calc(100% + #{u($spacing-unit / 2)});
        width: calc(100% + 80px);
        color: $white;
        font-family: $font-robotoSlab;
        background-color: $cc-grey;



        &::before {
            content: "";
            height: u($spacing-unit * 2);
            transform: translateY(-100%);
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        &-title {
            color: $cc-green;
            @include font-size(23);
            letter-spacing: 1.44px;
            font-family: $font-robotoSlab;
            text-transform: initial;
        }

        &-items {
            columns: 2;
            // min-height: 350px;

            @include mq ($from: $viewport--lg) {
                columns: 3;
            }

            flex-grow: 1;
            padding: 0;
            width: 100%;
            max-width: 75%;
        }

        &-item {
            &--wrapper {
                &.MultipleCategories{
                    break-inside: avoid;
                    display: flex;
                    flex-direction: column;
                }
            }

            list-style: none;
            @include font-size(14, 10);
            letter-spacing: .88px;
        }

        &-link {
            color: $black;
            text-decoration: none;
            text-transform: none;

            &:hover {
                color: $cc-green;

                .Icon-down {
                    * {
                        fill: $cc-green;
                    }
                }
            }

            .Icon-down {
                @include dimensions(7, 7);
                margin-left: -5px;
                margin-bottom: 4px;

                * {
                    transition: fill .4s;
                    fill: $black;
                }
            }
        }

        &-spotlight {
            position: relative;
            max-height: 250px;

            @include mq($from: $viewport--lg) {
                max-height: 450px;
            }

            display: flex;
            align-items: flex-end;
            border: 8px solid $cc-green;
            text-decoration: none;
            z-index: 2;
            width: 100%;
            max-width: 25%;

            &--media {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &--content {
                text-align: center;
                margin-top: auto;
                z-index: 2;
                position: relative;
                background-color: $white;
                padding: 10px;
                width: 100%;

                p {
                    color: $cc-black;
                    letter-spacing: .88px;
                    @include font-size(14, 0);
                    font-family: $font-robotoSlab;
                }

                .Spotlight-title {
                    color: $cc-green;
                }
            }
        }

        &-side {
            flex-shrink: 0;
        }

        &-wrapper {
            display: flex;
            justify-content: space-between;
            min-height: 200px;

            &--title {
                &:first-child {
                    margin-top: 0;
                }

                @include font-size(18, 10);
                color: $cc-green;
                font-weight: 600;
                margin-top: u($spacing-unit * 1.5);
                text-transform: initial;
            }

            &--items {
                margin-bottom: u($spacing-unit * 1.5);
            }
        }
    }

}
