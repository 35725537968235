.GoogleMaps {
    width: 100%;
    height: 100%;
}

.MarkerInfo {
    max-width: 205px;
    padding: 20px;
    height: auto;

    &-title {
        font-weight: normal;
        @include font-size(15, 5);
        color: $cc-green;

    }

    &-title,
    &-address,
    &-link {
        line-height: 15px;
    }

    &-address,
    &-link {
        @include font-size(12, 5);
        color: $cc-black;
        font-family: $font-fieldwork;
    }

    &-link {
        text-decoration: underline;
    }

}

.NoDealers {
    h1 {
        text-align: center;
        color: $cc-green;
        font-weight: 500;
    }
}

.Locations {

    &-wrapper {
        @include mq ($from: $viewport--md) {
            display: flex;
            justify-content: space-between;
        }
    }

    &-map {
        width: 100% !important;
        height: 100vh;


        @include mq($until: $viewport--md - 1) {
            width: calc(100% + 40px) !important;
            margin-left: calc(-20px);
            height: 500px;
            margin-bottom: u($spacing-unit * 2);
        }

        @include mq($from: $viewport--md, $until: $viewport--xl - 1) {
            max-width: calc(50% + 40px);
            margin-left: calc(-40px);
        }

        @include mq($from: $viewport--xl) {
            max-width: calc(50% + (100vw - #{u($structure-width)}) / 2);
            margin-left: calc(((100vw - #{u($structure-width)}) / 2) * -1);
        }
    }

    &-items,
    .NoDealers {

        @include mq ($from: $viewport--md, $until: $viewport--xl - 1) {
            width: calc(50% - #{u($spacing-unit * 2.5)});
        }

        @include mq ($from: $viewport--xl) {
            width: calc(50% - #{u($spacing-unit * 5)});
        }
    }

    &-item {
        position: relative;
        margin-bottom: u($spacing-unit * 1.5);
        background-color: $cc-grey;
        padding: u($spacing-unit * 1.75, $spacing-unit);

        @include mq ($from: $viewport--sm) {
            padding: u($spacing-unit * 1.75, $spacing-unit * 2.25);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-title {
        color: $cc-green;
        @include font-size(20, 2);
        font-weight: normal;

        @include mq ($from: $viewport--lg) {
            max-width: 300px;
        }
    }

    &-address {
        color: $cc-black;
        @include font-size(14, 10);
        font-family: $font-fieldwork;
    }

    &-links {
        display: inline-flex;
        flex-direction: column;
    }

    &-link {
        display: inline-block;
        @include font-size(14, 3);
        text-decoration: none;
        color: $cc-black;

        &--underline {
            span {
                text-decoration: underline;
            }
        }

        &:hover {
            color: $cc-green;
        }
    }

    &-icon {
        @include dimensions(14, 14);
        margin-right: 15px;
    }

    &-info {
        display: block;
        margin-top: u($spacing-unit);

        p {
            font-size: 14px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }


    &-head {
        flex-direction: column-reverse;
        display: flex;

        @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
            justify-content: space-between;
            flex-direction: row;
        }

        @include mq($from: $viewport--lg) {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }
    }

    &-labels {
        margin-bottom: 15px !important;

        @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            margin-left: 15px;
            margin-right: u((($spacing-unit * 2.25) + 5px) * -1);
        }

        @include mq($from: $viewport--lg) {
            margin-top: 0 !important;
            margin-left: 15px;
            margin-right: u((($spacing-unit * 2.25) + 5px) * -1);
        }
    }

    &-label {
        width: 100%;

        @include mq($from: $viewport--sm, $until: $viewport--md - 1) {
            max-width: 180px;
        }

        @include mq($from: $viewport--lg) {
            max-width: 180px;
        }

        text-align: center;
        @include font-size(13);
        color: $white;
        padding: 6px;
        font-family: $font-robotoSlab;

        &:last-child {
            margin-bottom: 0;
        }

        margin-bottom: u($spacing-unit / 2);
    }
}

.Filters {
    @include mq ($from: $viewport--sm) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    margin-bottom: u($spacing-unit * 3);

    &-label {

        margin-right: 5px;
        @include font-size(18, 0);
        color: $cc-green;
        font-weight: 300;
        font-family: $font-robotoSlab;
    }

    &-distance {
        margin: 0 u($spacing-unit);
    }

    &-input {
        border: 1px solid $cc-green;
        padding: 8px;
        height: 50px;
        width: 100%;
        margin: 15px 0;
        display: flex;
        align-items: center;

        @include mq ($from: $viewport--sm) {
            margin: 5px;
            max-width: 166px;

        }

        &--item {
            border: none;
            box-shadow: none;
            border-bottom: 1px solid $cc-green;
            width: 100%;

            &::placeholder,
            & {
                @include font-size(13, 0);
                font-family: $font-fieldwork;
                color: $cc-green;
            }
        }


        &,
        &::placeholder {
            @include font-size(13, 0);
            font-family: $font-fieldwork;
            color: $cc-green;
            display: flex;
            align-items: center;
        }


    }

    &-distanceOptions {
        transition: opacity .6s, visibility .6s;
        opacity: 0;
        visibility: hidden;
        border: 1px solid $cc-green;
        left: -1px;
        width: calc(100% + 2px);
        top: 100%;
        position: absolute;
        z-index: 2;
        background-color: $white;
    }

    &-option {
        padding: 8px;
        transition: background-color .4s, color .4s;
        @include font-size(13, 0);
        font-family: $font-fieldwork;
        color: $cc-green;

        &.is-active,
        &:hover {
            cursor: pointer;
            background-color: $cc-green;
            color: $white;
        }
    }
}
