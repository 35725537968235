/* Alert */
.#{$alert-namespace}Alert {
    position: relative;
    margin-bottom: u($spacing-unit);
    padding: em($alert-padding-t, $alert-padding-r, $alert-padding-b, $alert-padding-l);
    border: px($alert-border-width) solid;

    @if $alert-border-radius > 0 {
        border-radius: px($alert-border-radius);
    }

    &.is-dismissable {
        padding-right: u(double($alert-padding-r));
    }

    p,
    ul {
        margin-bottom: 0;

        + p,
        + ul {
            margin-top: u($spacing-unit);
        }
    }

    a:not(.Button--close) {
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }
}
