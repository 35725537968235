/* Objects: Toggle */

// Namespace
$toggle-namespace:                   $namespace !default;

// Dimensions
$toggle-width:                       30 !default;
$toggle-height:                      24 !default;

// Border
$toggle-border-width:                2 !default;
$toggle-border-radius:               2 !default;
