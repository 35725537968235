.Nav-magicLine {
    border-top: 5px solid $cc-green;
    display: block;
    transition: opacity .2s;
    z-index: 2;

    &:not(.is-loaded) {
        opacity: 0;
    }

    position: absolute;
    top: -10px;
    right: 0;
    left: 0;

    @include mq($until: $viewport--md - 1) {
        display: none;
    }

    // @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
    //     margin-left: 60px;
    // }

    // @include mq($from: $viewport--lg) {
    //     margin-left: 60px;
    // }

    @include mq ($from: $viewport--md, $until: $viewport--lg - 1) {
        margin-left: u($spacing-unit / 1.5);
    }

    @include mq ($from: $viewport--lg) {

        margin-left: u($spacing-unit * 2);
    }
}
