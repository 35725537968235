.ContentBlock {
    width: 100%;
    max-width: 785px;
    margin: 0 auto;

    &-typeVideo,
    &-typeMedia {
        margin: u($spacing-unit * 3, auto);

        @include mq ($from: $viewport--md) {
            margin: u($spacing-unit * 5, auto);
        }
    }

    &--large {
        max-width: 990px !important;
    }

    &-media {
        margin: u($spacing-unit, auto);

        @include mq ($from: $viewport--sm, $until: $viewport--md - 1) {
            margin: u($spacing-unit / 2, auto);
        }

        @include mq ($from: $viewport--md) {
            margin: u($spacing-unit, auto);
        }

        img {
            @include mq ($from: $viewport--sm) {
                max-height: 570px;
            }
        }

    }

    &-typeMedia {
        @include mq ($from: $viewport--sm) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &--half {
            .ContentBlock-media {
                width: 100%;

                @include mq ($from: $viewport--sm, $until: $viewport--md - 1) {
                    max-width: calc((100% / 2) - 20px);
                }

                @include mq ($from: $viewport--md) {
                    max-width: calc((100% / 2) - 40px);
                }
            }
        }


    }

    &-typeVideo {
        .Thumbnail {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, .3);
                transition: background-color .6s;
            }

            &:hover {
                cursor: pointer;

                &::before {
                    background-color: rgba(0, 0, 0, .0);
                }

                .Play-icon {

                    transform: scale(1.1);

                }
            }

            &-icon {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .Play-icon {
                @include dimensions(102, 102);
                transition: transform .4s;
            }
        }
    }

    &-heading1 {
        @include fluid-type(font-size, $viewport--xs, $viewport--md, 30, 36);
        color: $cc-green;
    }

    &-heading2 {
        @include fluid-type(font-size, $viewport--xs, $viewport--md, 25, 30);
    }

    &-heading3 {
        @include fluid-type(font-size, $viewport--xs, $viewport--md, 20, 25);
    }

    &-heading1,
    &-heading2,
    &-heading3 {
        font-weight: normal;
        margin-bottom: u($spacing-unit * 1.25);
    }

    &-typeText {
        line-height: 26px;
    }

    &-typeButton {
        margin: u($spacing-unit * 1.25, auto);
    }

    &.typeMediaBlock {
        max-width: 1060px !important;
        padding: u($spacing-unit * 2, 0);

        @include mq ($from: $viewport--md) {
            padding: u($spacing-unit * 5, 0);
        }

        @include mq ($from: $viewport--md) {
            display: flex;
            justify-content: space-between;

            .typeMediaBlock-content {
                margin-left: u($spacing-unit);
            }

            &--reverse {
                flex-direction: row-reverse;

                .typeMediaBlock-content {
                    margin-right: u($spacing-unit);
                    margin-left: 0;
                }
            }
        }

        &--bg {
            position: relative;

            &::before {
                position: absolute;
                content: '';
                top: 0;
                left: calc(((100vw - (100%)) / 2) * -1);
                width: 100vw;
                height: 100%;
                background-color: $cc-grey;
                z-index: -1;
            }

            .typeMediaBlock-media {
                border: 8px solid $white;
            }
        }
    }

    .typeMediaBlock {
        &-media {
            height: 100%;

            @include mq ($until: $viewport--md - 1) {
                margin-bottom: u($spacing-unit);
            }

            @include mq ($from: $viewport--md) {
                max-width: 488px;
            }
        }

        &-content {
            width: 100%;

            p {
                line-height: normal;
            }

            @include mq ($from: $viewport--md) {

                &,
                p {
                    max-width: 500px;
                }

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }
        }

        &-heading1,
        &-heading2,
        &-ondertitel {
            font-weight: normal;
        }

        &-heading1 {
            @include font-size(35, 13);
            color: $cc-green;
        }

        &-heading2 {
            @include font-size(20, 14);
            color: $cc-green;
        }

        &-ondertitel {
            @include font-size(18, 20);
        }
    }





    &-typeTabs {
        .CalendarPage & {
            margin-top: u($spacing-unit * 3);
        }

        margin-top: 100px;
        max-width: 1077px !important;

        .typeTabs {
            &-wrapper {

                visibility: hidden;
                height: 0;
                position: relative;


                &::before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: calc(((100vw - (100% - 15px)) / 2) * -1);
                    width: 100vw;
                    height: 100%;
                    background-color: $cc-grey;
                    z-index: -1;
                }

                .typeTabs-media {
                    visibility: hidden;
                    height: 0;
                    width: 0;
                    position: absolute;
                }

                &.is-active {
                    @include mq($from: $viewport--md) {
                        display: flex;
                    }

                    .typeTabs-media {
                        &.is-active {
                            position: relative;
                            width: auto;
                            height: 100%;
                            visibility: visible;
                        }
                    }

                    padding: u($spacing-unit * 4, 0);
                    height: auto;
                    visibility: visible;


                }
            }



            &-media {

                @include mq($from: $viewport--md) {
                    max-width: 488px !important;

                    img {
                        max-height: 410px !important;
                    }
                }

                margin-left: auto;
                border: 6px solid $white;
                height: 100%;






            }

            &-titles {
                margin: 1px 0 0 1px;
                display: flex;
                flex-wrap: wrap;

                @include mq($until: $viewport--md - 1) {
                    justify-content: center;
                }
            }

            &-title {
                margin: -1px 0 0 -1px;
                text-decoration: none;
                border: 1px solid $cc-green;
                padding: 15px 5px;
                display: block;

                @include mq($until: $viewport--sm - 1) {
                    width: auto;
                    min-width: 125px;
                }

                @include mq($from: $viewport--sm) {
                    width: 100%;
                    max-width: 200px;
                }

                text-align: center;
                color: $cc-green;
                transition: background-color .4s,
                color .4s;
                font-family: $font-robotoSlab;

                &:hover,
                &.is-active {
                    background-color: $cc-green;
                    color: $white;
                }
            }
        }

        .TabContent {
            width: 100%;

            @include mq($from: $viewport--md) {
                margin-right: u($spacing-unit);
            }

            @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
                max-width: 327px;
            }

            @include mq($from: $viewport--lg) {
                max-width: 497px;
            }

            &-item {
                margin-bottom: u($spacing-unit * 2);
            }

            &-title {
                display: flex;
                justify-content: space-between;
                @include font-size(20, 20);
                padding-bottom: 13px;
                border-bottom: 2px solid $cc-green;
                font-weight: normal;
                align-items: center;
                color: $cc-green;

                &:hover,
                &.is-active {
                    cursor: pointer;

                    .TabContent-icon {
                        #Group {
                            rect {
                                transform: rotate(90deg);
                            }

                            polygon {
                                opacity: 0;
                            }
                        }
                    }
                }
            }

            &-icon {
                @include dimensions(8, 8);

                * {
                    fill: $cc-green;

                    #Group {
                        rect {
                            transition: transform .3s;
                            transform-origin: 50% 50%;
                        }

                        polygon {
                            transition: opacity .3s;
                        }
                    }
                }
            }

            &-text {
                max-width: 500px;
                display: none;
            }
        }
    }
}


.MultiTabs {
    .CalendarPage & {
        margin-top: u($spacing-unit * 3);
    }

    margin-top: 100px;
    max-width: 1077px !important;

    &-titles {
        margin: 1px 0 0 1px;
        display: flex;
        flex-wrap: wrap;

        &--item {
            color: $cc-green;
            width: 100%;
            max-width: 200px;
            margin: -1px 0 0 -1px;
            text-decoration: none;
            border: 1px solid $cc-green;
            padding: 15px 5px;
            display: block;
            text-align: center;
            transition: background-color .4s, color .4s;
            cursor: pointer;

            &.is-active,
            &:hover {
                background-color: $cc-green;
                color: #fff;
            }
        }
    }

    .MultiTabs-background {
        position: relative;

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: calc(((100vw - (100% - 15px)) / 2) * -1);
            width: 100vw;
            height: 100%;
            background-color: $cc-grey;
            z-index: -1;
        }
    }

    &-contentWrapper {

        height: 0;
        opacity: 0;
        .MultiTabs-content{
            display: none;
        }

        &.is-active {
            opacity: 1;
            height: auto;
            .MultiTabs-content{
                display: flex;
            }
        }
    }

    &-content {
        @include mq($until: $viewport--md - 1) {
            display: block !important;
        }
        @include mq($from: $viewport--md) {
            display: flex;
        }

        padding: u($spacing-unit * 4, 0);
        // min-height: u(410px + $spacing-unit * 4);

        &--inhoud {
            width: 100%;

            @include mq($from: $viewport--md) {
                margin-right: u($spacing-unit * 2);
            }

            @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
                max-width: 327px;
            }

            @include mq($from: $viewport--lg) {
                max-width: 497px;
            }

            .Content {
                margin-bottom: u($spacing-unit * 2);

                &.is-active,
                &:hover {
                    h3 {
                        .TabContent-icon {
                            #Group {
                                rect {
                                    transform: rotate(90deg);
                                }

                                polygon {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }

                h3 {
                    display: flex;
                    justify-content: space-between;
                    @include font-size(20, 20);
                    padding-bottom: 13px;
                    border-bottom: 2px solid $cc-green;
                    font-weight: normal;
                    align-items: center;
                    color: $cc-green;
                    cursor: pointer;

                }

                span {
                    display: none;

                    p {

                        max-width: 500px;
                    }
                }

                &.active {
                    span {
                        display: block;
                    }
                }
            }
        }



        &--mediaWrapper {
            @include mq($from: $viewport--md) {
                max-width: 488px;
                margin-left: auto;
            }

            width: 100%;
        }

        &--media {
            border: 6px solid $white;
            height: auto;
            top: 0;
            z-index: 0;
            opacity: 0;

            &,
            * {
                height: 0;
                border: none;
            }

            &.is-active {
                opacity: 1;
                border: 6px solid $white;

                &,
                * {
                    height: auto;
                }
            }
        }
    }
}
