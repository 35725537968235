/* FlexEmbed */
.#{$flexEmbed-namespace}FlexEmbed {
    display: block;
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-bottom: 100%;
        width: 100%;
    }
}

.#{$flexEmbed-namespace}FlexEmbed-content {

    &,
    > iframe,
    > object,
    > embed,
    > video {
        height: 100% !important;
        width: 100% !important;

        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
    }
}

img.#{$flexEmbed-namespace}FlexEmbed-content {
    object-fit: cover;
}

// Modifiers
@each $ratio-name, $ratio-value in $flexEmbed-ratios {

    @each $antecedent, $consequent in $ratio-value {

        @if (type-of($antecedent) != number) {
            @error "`#{$antecedent}` needs to be a number.";
        }

        @if (type-of($consequent) != number) {
            @error "`#{$consequent}` needs to be a number.";
        }

        .FlexEmbed--#{$ratio-name}:before {
            padding-bottom: ($consequent/$antecedent) * 100%;
        }
    }
}
