/* Footer */
.Footer {
    background-color: $footer-bg-color;
    position: relative;

    .DealerOverviewPagePage & {
        z-index: 2;
    }

    .Footer-none & {
        &::before {
            display: none;
        }
    }

    &--white,
    .Footer-white & {

        &,
        .DigitalPulse-object {
            background-color: $white !important;
        }

        &::before {
            background-image: url('../img/icons/scratch-grey.svg') !important;
            // transform: rotate(0deg) !important;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 75px;
        background-image: url('../img/icons/scratch.svg');
        background-repeat: no-repeat;
        transform: rotate(180deg);
        background-size: cover;
    }

    .Nav--socialMedia {
        .Nav-link {
            &:hover {
                .Nav-icon {
                    * {
                        fill: $cc-green;
                    }
                }
            }
        }

        .Nav-icon {
            * {
                fill: $cc-black;
            }
        }
    }

    // Top
    &-top {
        @include mq ($from: 460) {
            display: flex;
        }

        padding-top: u($spacing-unit * 8);
        padding-bottom: u($spacing-unit * 2);
    }

    // Bottom
    &-bottom {
        border-top: 1px solid rgba($cc-black, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: u($spacing-unit * 2);
        padding-bottom: u($spacing-unit * 2);
    }
}
