/* Outdated Browser */
 .Outdated {
    background-color: $red;
    color: $white;
    font-size: 12px;
    padding: 12px 24px;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1500;

    &-title {
        display: block;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 6px;
    }

    &-text {
        margin-bottom: 0;
        line-height: 1;
    }

    &-button {
        border: 2px solid $white;
        color: $white;
        cursor: pointer;
        display: block;
        font-weight: 700;
        line-height: 1;
        padding: 12px 24px;
        text-align: center;
        text-decoration: none;
        width: 230px; /*need for IE*/

        position: absolute;
        top: 12px;
        right: 24px;

        &:hover {
            background-color: $white;
            color: $red;
        }
    }

    * html & {
        position: absolute;
    }
}