.OverviewPagePage {
    .Main {
        overflow-y: hidden;
    }

    .Overview {
        &-intro {
            max-width: 604px;
            text-align: center;
            margin: u($spacing-unit * 3, auto, $spacing-unit * 5, auto);
            .Title-line{
                font-family: $font-robotoSlab !important;
                text-transform: initial;

            }
            .CustomText{
                display: block;
                max-width: 500px;
                margin: u(0, auto);
            }
        }
    }
}
