.Search {
    &-form {
        border-bottom: 1px solid $white;
        max-width: 125px;
        display: flex;

        @include mq ($from: $viewport--sm) {
            margin-right: u($spacing-unit * 2);
        }

        margin-right: u($spacing-unit);

        &--input {
            background-color: transparent;
            border: none;
            width: 100%;

            &,
            &::placeholder {
                color: $white;
                font-family: $font-robotoSlab;
                font-size: 14px;
            }
        }

        &--icon {
            margin-bottom: 3px;
        }
    }
}

.SearchResultsPage {
    background-color: $cc-grey;

    .SearchResults {
        max-width: 1197px;
        margin: 0 auto;
        margin-top: u($spacing-unit * 5);

        .Title-line {
            font-family: $font-robotoSlab !important;
            text-transform: initial !important;
        }

        .Search-form {
            max-width: 210px;
            margin-left: auto;
            margin-right: 0;
            border-color: $cc-green;
            padding-bottom: u($spacing-unit / 2);

            .Search-form--input {

                opacity: .6;

                &,
                &::placeholder {
                    color: $cc-black;
                    font-size: 20px;
                    line-height: 38px;
                    font-family: $font-fieldwork !important;
                }
            }

            .Search-form--icon {
                @include dimensions(18, 18);
                opacity: .6;

                * {
                    stroke: $cc-black;
                }
            }
        }
    }
}

.Results {

    &-item {
        border-bottom: 1px solid $cc-green;
        margin-bottom: u($spacing-unit);
        padding-bottom: u($spacing-unit);

        &:last-child {
            border: none;
        }
    }

    &-title {
        @include font-size(20, 0);
        color: $cc-green;
        font-weight: normal;
    }

    &-url {
        @include font-size(16, 10);
        color: $cc-black;
        opacity: .7;
        font-family: $font-fieldwork;
        line-height: 26px;
    }

    p {
        line-height: normal;
        color: $cc-black;
        &:last-child{
            margin-bottom: 0 !important;
        }
    }
}
